<template>
  <div class="l-privacy">
    
    <div class="l-header">
      <img
        class="l-header__img"
        src="../../images/LogoPinup_Fin_1024x636.jpg"
      />
    </div>
    <div class="l-privacy__ctn">
      <div class="l-privacy__back-btn">
      <router-link to="/" class="c-btn c-btn--back" />
    </div>
      <h2>Privacy Policy</h2>
      <p>
        Pinupwarlords project accessible at Pinupwarlords.com (“Website”) seeks
        to offer a unique gaming experience to you, the user (“You”)
        (Pinupwarlords.com collectively referred to as “We/Our/Us”). We at
        Pinupwarlords Project , are committed to protecting Your information to
        the best of Our ability while making no compromises with the premium
        gaming experience We endeavor to offer.
      </p>
      <p>
        We understand that You may have some questions about how We collect,
        use, share and handle Your information. Therefore, in the interest of
        transparency, We have detailed Our privacy policy below.
      </p>
      <ul>
        <li>Who are We? And what We do?</li>
      </ul>
      <p>
        Pinupwarlords project is a unique blockchain-based trading cards and
        collectible cards game. Broadly speaking, Pinupwarlords uses
        non-fungible tokens (NFTs), the Worldwide Asset eXchange (“WAX") NFTs
        standards and The WAX blockchain to provide You with one of a kind card
        trading , collecting, playing experience.
      </p>
      <ul>
        <li>
          Why do We collect information from You? What information do We collect
          from You?
        </li>
      </ul>

      <p>
        We necessarily need to access some of Your information to provide You
        with a good gaming experience. Aside from such necessary information, We
        also collect some other information. Understandably, Your refusal to
        provide Us the necessary information will constrain Our ability to
        provide You with access to Pinupwarlords game either in part or full.
      </p>
      <p>
        An indicative list of information collected by Us: We may collect
        information You provide to Us like registration details supplied by You
        upon signing up with Us, information You provide when You mail Us
        (whether as a user, potential user, supplier, employee, contractor,
        consultant, etc.) and when You subscribe to Our newsletter, Your
        responses to Our questionnaires/surveys, etc.
      </p>
      <p>
        Further, We may also collect email address, Crypto wallet address,
        device information, usage information, network information, (e.g. IP
        address, type of browser and operating system, and details of referring
        Uniform Resource Locator links, etc. ) information about Your usage of
        Our Website and the Game Name (e.g. how You use the Website, Your use
        patterns, location data, details of how You/ Your device interacts with
        the Game Name /Website, etc.).
      </p>
      <ul>
        <li>How does Our Website collect and use cookies?</li>
      </ul>
      <p>
        As mentioned above, while some of the information We collect is provided
        by You directly to Us but some of the information is obtained by
        analyzing and recording Your use of Our Website by using cookies
        technologies, other similar technologies, etc.
      </p>
      <p>
        Such information is used by Us to customize Your gaming experience by
        saving Your preferences for future use, analyzing and aggregating data
        to improve Pinupwarlords project and Website, providing You with
        relevant information to play the game.
      </p>
      <p>
        You can review the permissions granted by You to Pinupwarlords Website
        to collect and use cookies by modifying the relevant settings of Your
        browser. Similarly, a pop-up window appears on the Website upon Your
        initial visit seeking Your consent for Our Cookie Policy. You may review
        the terms of the same and grant/refuse the permissions sought therein,
        in addition to Your browser-specific cookie permissions. But do note
        that disabling all cookies and related permissions, will constrain Our
        ability to provide You access to the Game and the Website either in part
        or full.
      </p>
      <p>
        Further, We may also use cookie technologies and other such technologies
        in the periodic and promotional emails/newsletters, etc. We send to You.
      </p>
      <p>
        Additionally, We may make use of web-beacons on Our Website, webpages,
        emails, newsletters, etc. which may allow Us to transfer or collect Your
        information through electronic images. This may help Us understand and
        analyze the nature of Your interaction with Our Website, webpages,
        emails, newsletters, etc.
      </p>
      <p>
        Similarly, Pinup warlords project may also contain web-beacons from
        other providers and We may place Our web-beacons on other websites,
        advertisements, etc. This may help Us collect information about Your
        online behavior (across websites, applications, etc.), better analyze
        user behavior, generate useful data to improve Our services, promotional
        and marketing strategies, advertising mechanism, and business
        performance.
      </p>
      <ul>
        <li>How do We use Your information?</li>
      </ul>
      <p>
        Your information is mainly used to provide You with Pinupwarlords update
        and improve upon the Game when required, give You a customized and
        personalized gaming experience and ancillary matters (provide standard
        security updates from time to time, etc.)
      </p>
      <p>
        We use Your information for several purposes which can be broadly
        characterized as follows:
      </p>
      <ul>
        <li>
          Offering You a unique gaming experience: This includes the necessary
          data We need to provide You with Pinupwarlords and also to personalize
          and customize Your gaming experience.
        </li>
        <li>
          For operational purposes: This includes collecting, analyzing,
          aggregating, etc. Your information for general business purposes. More
          specifically, We also use Your information to analyze Our business
          performance and improve Our business decisions and services.
        </li>
        <li>
          Identifying and fixing problems: Your information is used to identify, 
          diagnose and remedy any problems which arise (or may potentially arise) in Your/other users’ usage of this Website.
        </li>
        <li>
          For customer outreach: We may use Your information to send You periodic emails relating to Pinupwarlords 
          (e.g. new features available, any upcoming contests, survey invites, etc.). 
          Similarly, Your information is also used to respond to Your 
          queries and address any requests or grievances You may have.
        </li>
        <li>
          For promotional, marketing, and advertising purposes: 
          Aside from using Your information in keeping in touch with You via periodic emails, etc. 
          We may also use Your information for promotional and marketing purposes.  
        </li>
        <li>
          What precautions do We take to secure Your information and stay up-to-date with the times?
          At Pinupwarlord project, We take the safety and security of Your information very seriously. 
          As such, We have employed an advance security protocol and features to handle Your information safely and securely.
        </li>
      </ul>
      <p>
        In addition to using the services of a secure server, 
        We ensure that all sensitive personal information is transmitted by Us, 
        via SSL (Secure Socket Layer) technology before being encrypted into Our gateway provider’s database, 
        for use only by authorized personnel on a need-to-know basis.
      </p>

      <ul>
        <li>Is Your information/data being disclosed to anyone else?</li>
      </ul>
      <p>
        Your information is being disclosed to third parties, 
        to the extent such disclosure is required to make Pinupwarlords available to You.
        We may also share Your information with the relevant regulatory authorities 
        (as required under applicable laws, to protect Us legally, for responding to legal proceedings, 
        and for the well-being of our customers or the public at large) and Our vendors. 
      </p>

      <ul>
        <li>Any questions?</li>
      </ul>
      <p>
        If You want Us to delete Your information in Our possession,
        please feel free to reach out to Us at 
        privacy@pinupwarlords.com, we will reply within 15 days.
      </p>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from '../../components/AppFooter.vue';

export default {
  name: "PrivacyPage",
    components: {
    AppFooter
  },
  created () {
    window.scrollTo(0,0);
  },
};
</script>

<style lang="scss" scoped>
</style>
