module.exports = {
    'WAX': {
        apiUri: "https://wax.greymass.com:443",
        contract: "pinupwarlord",
        chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4"
    },
    "discord": {
        clientId: "975652920173416468",
        clientSecret: "-FUi4Gs0ipUUpN6vdlKeuhr07rLWCxdg",
        url: "https://discord.com/api/oauth2/authorize?client_id=975652920173416468&redirect_uri=https%3A%2F%2Fpinupwarlords.com%2F&response_type=token&scope=identify",
        port: 53134
    },
}