<template>
  <section id="gamefi" class="gamefi-wrap container container-vertical">
    <div>
      <div class="gamefi-wrap__header">
        <h1 class="title title-gradient">GAMEFI</h1>
      </div>
      <div class="star-cards">
        <article
          class="star-card"
          data-aos="fade-up"
          data-aos-easing="ease"
        >
          <div class="star-card__left">
            <star-icon />
            <p>Token reward pool</p>
          </div>
          <div class="star-card__right">
            <p>{{ token }} WAX</p>
          </div>
        </article>
        <article
          class="star-card"
          data-aos="fade-up"
          data-aos-easing="ease"
        >
          <div class="star-card__left">
            <star-icon />
            <p>Collectors reward pool</p>
          </div>
          <div class="star-card__right">
            <p>{{ collector }} WAX</p>
          </div>
        </article>
        <article
          class="star-card"
          data-aos="fade-up"
          data-aos-easing="ease"
        >
          <div class="star-card__left">
            <star-icon />
            <p>Leaderboard pool</p>
          </div>
          <div class="star-card__right">
            <p>{{ leaderboard }} WAX</p>
          </div>
        </article>
      </div>
    </div>
    <div class="versus-cards">
      <h1 class="versus-cards__header">RECENT BATTLES</h1>
      <article
        v-for="battle in recentBattles"
        :key="battle.transaction_id"
        class="versus-cards__card"
        style="cursor:pointer"
        @click="verifyBattle(battle.transaction_id)"
      >
        <div class="main">
          <p>{{ battle.user1 }}</p>
          <div class="main__circle">
            <span>VS</span>
          </div>
          <p>{{ battle.user2 }}</p>
        </div>
        <div class="tooltip">
          <span class="tooltip__line"></span>
          <p class="tooltip__text">{{ battle.winner? battle.winner + " WON!" : "DRAW!" }}</p>
          <span class="tooltip__line"></span>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
import Eos from '../classes/eos.js';
import constants from '../constants/index.js'
let eos = new Eos(constants.defaultChain);

import StarIcon from "./StarIcon.vue";
export default {
  name: "GamefiSection",
  components: { StarIcon },
  data() {
    return {
      pools: null,
      recentBattles: []
    };
  },
  computed: {
    leaderboard: function() {
      if(!this.pools) {
        return 0;
      }
      return Number(this.pools.leaderboard.split(' ')[0]).toFixed(4);
    },
    token: function() {
      if(!this.pools) {
        return 0;
      }
      return Number(this.pools.token.split(' ')[0]).toFixed(4);
    },
    collector: function() {
      if(!this.pools) {
        return 0;
      }
      return Number(this.pools.collector.split(' ')[0]).toFixed(4);
    },
  },
  async mounted() {
    this.pools = await eos.getPools();
    this.recentBattles = await eos.getRecentBattles();
  },
  methods: {
    verifyBattle(transactionId) {
      var url = 'https://wax.bloks.io/transaction/' + transactionId + "?tab=traces";
      window.open(url, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.gamefi-wrap {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  min-height: 100vh;
  width: 100%;
  background: url("../images/gamefi/bg.png") no-repeat bottom center/cover;
  gap: 2rem;

  @include media-max(1000px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .coming-soon {
    position: relative;
    width: 150px;
    height: 70px;
    z-index: 10;
    img {
      height: 100%;
      width: auto;
    }
    left: 2rem;
    bottom: 1rem;
  }

  &__header {
    display: flex;
    align-items: center;
  }
}

.star-cards {
  margin-top: 3rem;
}

.star-card {
  display: flex;
  color: white;
  font-size: 18px;
  margin-bottom: 1rem;
  &__left {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1.5rem 1rem;
    flex: 1;
    background: #101117;
    box-shadow: inset 0px 0px 15px rgba(255, 255, 255, 0.3);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    h1 {
      font-size: 18px;
    }
  }
  &__right {
    border-left: 10px solid $orange !important;
    border-right: 10px solid $orange !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0.5rem;
    text-align: center;
    width: 45%;
    background: linear-gradient(
        103.14deg,
        rgba(247, 132, 26, 0.4) -3.76%,
        rgba(247, 132, 26, 0) 48.28%
      ),
      linear-gradient(
        282.14deg,
        rgba(247, 132, 26, 0.4) -1.45%,
        rgba(247, 132, 26, 0) 45.98%
      ),
      rgba(255, 255, 255, 0.07);
    border: 1px solid rgba(255, 255, 255, 0.15);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.versus-cards {
  position: relative;
  height: 100%;
  background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.2290266448376226) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url("../images/gamefi/right_bg.png") no-repeat center center/cover;
  border-radius: 15px;
  border: 1px solid #505050;
  padding: 2rem;

  &__header {
    margin-bottom: 1rem;

    text-align: center;
    font-family: "Crimson Text", serif;
    font-size: 26px;
    font-weight: 400;
  }

  &__card {
    margin-bottom: 1rem;
    .main {
      background: #101117;
      box-shadow: inset 0px 0px 15px rgba(255, 255, 255, 0.3);
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;

      &__circle {
        padding: 0.6rem;
        border-radius: 50%;

        background: radial-gradient(circle, #f7841a 0%, rgba(0, 0, 0, 0) 100%);
        border: 1px solid rgba(255, 255, 255, 0.05);
        box-shadow: inset 0px -6px 20px #101117,
          inset 0px 3px 6px rgba(255, 255, 255, 0.2);
        position: relative;
        span {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: block;
        }
      }
    }

    .tooltip {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.5rem;
      &__line {
        height: 1px;
        width: 10%;
        background: $orange;
        box-shadow: 0px 0px 5px $orange;
      }
      &__text {
        margin: 0 1rem;
        color: $orange;
      }
    }
  }
}
</style>