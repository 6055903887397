<template>
  <section class="scroll-down">
    <button class="scroll-down__button" @click="$emit('click')">
      <svg
        id="Capa_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        
        viewBox="0 0 451.847 451.847"
        style="enable-background: new 0 0 451.847 451.847"
        xml:space="preserve"
      >
        <g>
          <path
            d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
          />
        </g>
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
      </svg>
    </button>
  </section>
</template>

<script>
export default {
  name: "ScrollDown",
};
</script>

<style lang="scss" scoped>
.scroll-down{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__button{
        svg{
            height: 30px;
            width: 30px;
            fill: white;
            opacity: .85;
            transition: all ease-in-out .2s
        }
    }
    &__button:hover{
        svg{
            opacity: 1
            
        }
    }
}
</style>