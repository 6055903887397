let url = window.location.hostname;
if(url.includes("test.") || url.includes("localhost")) {
    module.exports = {
        'environment': "test",
        'defaultChain': "WAX",
        'atomicdropsx': 'atomicdropsx',
        'atomicassets': 'atomicassets',
        'waxAccountName': 'pinupwartest',
        'collectionName': 'pinupwartest',
        'heelsAccountName': 'pinup12shoes',
        'heelsTokenName': 'SHOES',
        'campaignTemplateId': 496085,
        'campaignDropId': 90026,
        'squadTemplateId': 315039,
        'squadDropId': 83266,
        'squadLeaderQueues':["free","100","500","1000","50wax","100wax"],
        'squadLeaderAmmoReq':[20,20,100,200,20,20],
        'unfreezeCost':200,
    
        'templates':
        {
            317078: {faction: 'faction1', type: 'trooper'},
            318859: {faction: 'faction1', type: 'nurse'},
            326403: {faction: 'faction1', type: 'radio'},
            318891: {faction: 'faction1', type: 'sniper'},
            318894: {faction: 'faction1', type: 'paratrooper'},
            318897: {faction: 'faction1', type: 'gunner'},
            318899: {faction: 'faction1', type: 'tank driver'},
            318901: {faction: 'faction1', type: 'pilot'},
            318904: {faction: 'faction1', type: 'lieutenant'},
            318907: {faction: 'faction1', type: 'captain'},
            318909: {faction: 'faction1', type: 'colonel'},
            318911: {faction: 'faction1', type: 'warlord'},
    
            318863: {faction: 'faction2', type: 'trooper'},
            318861: {faction: 'faction2', type: 'nurse'},
            326404: {faction: 'faction2', type: 'radio'},
            318892: {faction: 'faction2', type: 'sniper'},
            318895: {faction: 'faction2', type: 'paratrooper'},
            318898: {faction: 'faction2', type: 'gunner'},
            318900: {faction: 'faction2', type: 'tank driver'},
            318902: {faction: 'faction2', type: 'pilot'},
            318905: {faction: 'faction2', type: 'lieutenant'},
            318908: {faction: 'faction2', type: 'captain'},
            318910: {faction: 'faction2', type: 'colonel'},
            318912: {faction: 'faction2', type: 'warlord'},
    
            496091: {faction: 'faction3', type: 'trooper'},
            496092: {faction: 'faction3', type: 'nurse'},
            496093: {faction: 'faction3', type: 'radio'},
            496094: {faction: 'faction3', type: 'sniper'},
            496095: {faction: 'faction3', type: 'paratrooper'},
            496096: {faction: 'faction3', type: 'gunner'},
            496097: {faction: 'faction3', type: 'tank driver'},
            496098: {faction: 'faction3', type: 'pilot'},
            496099: {faction: 'faction3', type: 'lieutenant'},
            496100: {faction: 'faction3', type: 'captain'},
            496101: {faction: 'faction3', type: 'colonel'},
            496102: {faction: 'faction3', type: 'warlord'},
    
            496105: {faction: 'faction4', type: 'trooper'},
            496106: {faction: 'faction4', type: 'nurse'},
            496107: {faction: 'faction4', type: 'radio'},
            496108: {faction: 'faction4', type: 'sniper'},
            496109: {faction: 'faction4', type: 'paratrooper'},
            496110: {faction: 'faction4', type: 'gunner'},
            496111: {faction: 'faction4', type: 'tank driver'},
            496112: {faction: 'faction4', type: 'pilot'},
            496113: {faction: 'faction4', type: 'lieutenant'},
            496114: {faction: 'faction4', type: 'captain'},
            496116: {faction: 'faction4', type: 'colonel'},
            496117: {faction: 'faction4', type: 'warlord'},
        },
    
        'weaponTemplates':
        {
            334743: 'grenade',
            334744: 'machine gun',
            334745: 'bazooka',
            334746: 'artillery',
            334747: 'tank',
            334749: 'cruise missile',
            334750: 'jet',
            334751: 'bomber',
            334755: 'icbm',
        },

        'weaponTemplatesOld':
        {
            0: 'grenade',
            1: 'machine gun',
            2: 'bazooka',
            3: 'artillery',
            4: 'tank',
            5: 'cruise missile',
            6: 'jet',
            7: 'bomber',
            8: 'icbm',
        },
    
        'soldierFirepower':
        {
            trooper: 2,
            nurse: 1,
            radio: 2,
            sniper: 2,
            paratrooper: 4,
            gunner: 2,
            'tank driver': 2,
            pilot: 3,
            lieutenant: 4,
            captain: 4,
            colonel: 6,
            warlord: 10
        },
        
        'soldierInitiative':
        {
            trooper: 1,
            nurse: 4,
            radio: 2,
            sniper: 4,
            paratrooper: 2,
            gunner: 2,
            'tank driver': 3,
            pilot: 4,
            lieutenant: 5,
            captain: 10,
            colonel: 15,
            warlord: 20
        },
        
        'weaponFirepower':
        {
            grenade: 2,
            'machine gun': 3,
            bazooka: 4,
            artillery: 5,
            tank: 10,
            'cruise missile': 12,
            jet: 15,
            bomber: 20,
            icbm: 30,
        },
    }
} else {
    module.exports = {
        'environment': "prod",
        'defaultChain': "WAX",
        'atomicdropsx': 'atomicdropsx',
        'atomicassets': 'atomicassets',
        'waxAccountName': 'pinupwarlord',
        'collectionName': 'pinupwarlord',
        'heelsAccountName': 'pinup24heels',
        'heelsTokenName': 'HEELS',
        'campaignTemplateId': 490221,
        'campaignDropId': 91396,
        'squadTemplateId': 490220,
        'squadLeaderQueues':["free","100","500","1000","50wax","100wax"],
        'squadLeaderAmmoReq':[20,20,100,200,20,20],
        'unfreezeCost':200,
        'squadDropId': 90339,
    
        'templates':
        {
            515933: {faction: 'alexander', type: 'trooper'},
            515934: {faction: 'alexander', type: 'nurse'},
            515943: {faction: 'alexander', type: 'radio'},
            515944: {faction: 'alexander', type: 'sniper'},
            515945: {faction: 'alexander', type: 'paratrooper'},
            515946: {faction: 'alexander', type: 'gunner'},
            515947: {faction: 'alexander', type: 'tank driver'},
            515948: {faction: 'alexander', type: 'pilot'},
            515949: {faction: 'alexander', type: 'lieutenant'},
            515950: {faction: 'alexander', type: 'captain'},
            515951: {faction: 'alexander', type: 'colonel'},
            515952: {faction: 'alexander', type: 'warlord'},
    
            515954: {faction: 'antoniolupa', type: 'trooper'},
            515955: {faction: 'antoniolupa', type: 'nurse'},
            515956: {faction: 'antoniolupa', type: 'radio'},
            515957: {faction: 'antoniolupa', type: 'sniper'},
            515958: {faction: 'antoniolupa', type: 'paratrooper'},
            515959: {faction: 'antoniolupa', type: 'gunner'},
            515960: {faction: 'antoniolupa', type: 'tank driver'},
            515961: {faction: 'antoniolupa', type: 'pilot'},
            515962: {faction: 'antoniolupa', type: 'lieutenant'},
            515963: {faction: 'antoniolupa', type: 'captain'},
            515964: {faction: 'antoniolupa', type: 'colonel'},
            515965: {faction: 'antoniolupa', type: 'warlord'},
    
            515966: {faction: 'ataturk', type: 'trooper'},
            515967: {faction: 'ataturk', type: 'nurse'},
            515968: {faction: 'ataturk', type: 'radio'},
            515969: {faction: 'ataturk', type: 'sniper'},
            515970: {faction: 'ataturk', type: 'paratrooper'},
            515971: {faction: 'ataturk', type: 'gunner'},
            515972: {faction: 'ataturk', type: 'tank driver'},
            515973: {faction: 'ataturk', type: 'pilot'},
            515974: {faction: 'ataturk', type: 'lieutenant'},
            515976: {faction: 'ataturk', type: 'captain'},
            515975: {faction: 'ataturk', type: 'colonel'},
            515977: {faction: 'ataturk', type: 'warlord'},
    
            515982: {faction: 'bismarck', type: 'trooper'},
            515983: {faction: 'bismarck', type: 'nurse'},
            515984: {faction: 'bismarck', type: 'radio'},
            515985: {faction: 'bismarck', type: 'sniper'},
            515986: {faction: 'bismarck', type: 'paratrooper'},
            515987: {faction: 'bismarck', type: 'gunner'},
            515988: {faction: 'bismarck', type: 'tank driver'},
            515989: {faction: 'bismarck', type: 'pilot'},
            515990: {faction: 'bismarck', type: 'lieutenant'},
            515992: {faction: 'bismarck', type: 'captain'},
            515991: {faction: 'bismarck', type: 'colonel'},
            515993: {faction: 'bismarck', type: 'warlord'},

            515994: {faction: 'caesar', type: 'trooper'},
            515995: {faction: 'caesar', type: 'nurse'},
            515996: {faction: 'caesar', type: 'radio'},
            515997: {faction: 'caesar', type: 'sniper'},
            515998: {faction: 'caesar', type: 'paratrooper'},
            515999: {faction: 'caesar', type: 'gunner'},
            516000: {faction: 'caesar', type: 'tank driver'},
            516001: {faction: 'caesar', type: 'pilot'},
            516002: {faction: 'caesar', type: 'lieutenant'},
            516003: {faction: 'caesar', type: 'captain'},
            516004: {faction: 'caesar', type: 'colonel'},
            516005: {faction: 'caesar', type: 'warlord'},

            516006: {faction: 'crazyhorse', type: 'trooper'},
            516007: {faction: 'crazyhorse', type: 'nurse'},
            516008: {faction: 'crazyhorse', type: 'radio'},
            516009: {faction: 'crazyhorse', type: 'sniper'},
            516010: {faction: 'crazyhorse', type: 'paratrooper'},
            516011: {faction: 'crazyhorse', type: 'gunner'},
            516012: {faction: 'crazyhorse', type: 'tank driver'},
            516013: {faction: 'crazyhorse', type: 'pilot'},
            516014: {faction: 'crazyhorse', type: 'lieutenant'},
            516015: {faction: 'crazyhorse', type: 'captain'},
            516016: {faction: 'crazyhorse', type: 'colonel'},
            516017: {faction: 'crazyhorse', type: 'warlord'},

            516023: {faction: 'degaulle', type: 'trooper'},
            516024: {faction: 'degaulle', type: 'nurse'},
            516025: {faction: 'degaulle', type: 'radio'},
            516026: {faction: 'degaulle', type: 'sniper'},
            516027: {faction: 'degaulle', type: 'paratrooper'},
            516028: {faction: 'degaulle', type: 'gunner'},
            516030: {faction: 'degaulle', type: 'tank driver'},
            516031: {faction: 'degaulle', type: 'pilot'},
            516033: {faction: 'degaulle', type: 'lieutenant'},
            516034: {faction: 'degaulle', type: 'captain'},
            516035: {faction: 'degaulle', type: 'colonel'},
            516036: {faction: 'degaulle', type: 'warlord'},

            516041: {faction: 'elcid', type: 'trooper'},
            516042: {faction: 'elcid', type: 'nurse'},
            516043: {faction: 'elcid', type: 'radio'},
            516044: {faction: 'elcid', type: 'sniper'},
            516056: {faction: 'elcid', type: 'paratrooper'},
            516064: {faction: 'elcid', type: 'gunner'},
            516058: {faction: 'elcid', type: 'tank driver'},
            516059: {faction: 'elcid', type: 'pilot'},
            516060: {faction: 'elcid', type: 'lieutenant'},
            516061: {faction: 'elcid', type: 'captain'},
            516062: {faction: 'elcid', type: 'colonel'},
            516063: {faction: 'elcid', type: 'warlord'},

            516065: {faction: 'ghengiskhan', type: 'trooper'},
            516066: {faction: 'ghengiskhan', type: 'nurse'},
            516067: {faction: 'ghengiskhan', type: 'radio'},
            516068: {faction: 'ghengiskhan', type: 'sniper'},
            516069: {faction: 'ghengiskhan', type: 'paratrooper'},
            516070: {faction: 'ghengiskhan', type: 'gunner'},
            516071: {faction: 'ghengiskhan', type: 'tank driver'},
            516072: {faction: 'ghengiskhan', type: 'pilot'},
            516073: {faction: 'ghengiskhan', type: 'lieutenant'},
            516074: {faction: 'ghengiskhan', type: 'captain'},
            516075: {faction: 'ghengiskhan', type: 'colonel'},
            516076: {faction: 'ghengiskhan', type: 'warlord'},

            495378: {faction: 'giap', type: 'trooper'},
            495379: {faction: 'giap', type: 'nurse'},
            495380: {faction: 'giap', type: 'radio'},
            495381: {faction: 'giap', type: 'sniper'},
            495382: {faction: 'giap', type: 'paratrooper'},
            495383: {faction: 'giap', type: 'gunner'},
            495384: {faction: 'giap', type: 'tank driver'},
            495385: {faction: 'giap', type: 'pilot'},
            495386: {faction: 'giap', type: 'lieutenant'},
            515932: {faction: 'giap', type: 'captain'},
            495388: {faction: 'giap', type: 'colonel'},
            495389: {faction: 'giap', type: 'warlord'},

            516077: {faction: 'guanyu', type: 'trooper'},
            516078: {faction: 'guanyu', type: 'nurse'},
            516079: {faction: 'guanyu', type: 'radio'},
            516080: {faction: 'guanyu', type: 'sniper'},
            516081: {faction: 'guanyu', type: 'paratrooper'},
            516082: {faction: 'guanyu', type: 'gunner'},
            516083: {faction: 'guanyu', type: 'tank driver'},
            516084: {faction: 'guanyu', type: 'pilot'},
            516085: {faction: 'guanyu', type: 'lieutenant'},
            516086: {faction: 'guanyu', type: 'captain'},
            516087: {faction: 'guanyu', type: 'colonel'},
            516088: {faction: 'guanyu', type: 'warlord'},

            516089: {faction: 'gustavus', type: 'trooper'},
            516090: {faction: 'gustavus', type: 'nurse'},
            516091: {faction: 'gustavus', type: 'radio'},
            516092: {faction: 'gustavus', type: 'sniper'},
            516093: {faction: 'gustavus', type: 'paratrooper'},
            516094: {faction: 'gustavus', type: 'gunner'},
            516095: {faction: 'gustavus', type: 'tank driver'},
            516096: {faction: 'gustavus', type: 'pilot'},
            516097: {faction: 'gustavus', type: 'lieutenant'},
            516098: {faction: 'gustavus', type: 'captain'},
            516099: {faction: 'gustavus', type: 'colonel'},
            516100: {faction: 'gustavus', type: 'warlord'},

            516102: {faction: 'gwangaetto', type: 'trooper'},
            516103: {faction: 'gwangaetto', type: 'nurse'},
            516104: {faction: 'gwangaetto', type: 'radio'},
            516105: {faction: 'gwangaetto', type: 'sniper'},
            516106: {faction: 'gwangaetto', type: 'paratrooper'},
            516107: {faction: 'gwangaetto', type: 'gunner'},
            516108: {faction: 'gwangaetto', type: 'tank driver'},
            516109: {faction: 'gwangaetto', type: 'pilot'},
            516110: {faction: 'gwangaetto', type: 'lieutenant'},
            516111: {faction: 'gwangaetto', type: 'captain'},
            516112: {faction: 'gwangaetto', type: 'colonel'},
            516113: {faction: 'gwangaetto', type: 'warlord'},

            516118: {faction: 'harrychauvel', type: 'trooper'},
            516119: {faction: 'harrychauvel', type: 'nurse'},
            516121: {faction: 'harrychauvel', type: 'radio'},
            516122: {faction: 'harrychauvel', type: 'sniper'},
            516123: {faction: 'harrychauvel', type: 'paratrooper'},
            516124: {faction: 'harrychauvel', type: 'gunner'},
            516125: {faction: 'harrychauvel', type: 'tank driver'},
            516126: {faction: 'harrychauvel', type: 'pilot'},
            516127: {faction: 'harrychauvel', type: 'lieutenant'},
            516128: {faction: 'harrychauvel', type: 'captain'},
            516129: {faction: 'harrychauvel', type: 'colonel'},
            516131: {faction: 'harrychauvel', type: 'warlord'},

            516134: {faction: 'koutouzov', type: 'trooper'},
            516135: {faction: 'koutouzov', type: 'nurse'},
            516136: {faction: 'koutouzov', type: 'radio'},
            516137: {faction: 'koutouzov', type: 'sniper'},
            516138: {faction: 'koutouzov', type: 'paratrooper'},
            516139: {faction: 'koutouzov', type: 'gunner'},
            516140: {faction: 'koutouzov', type: 'tank driver'},
            516141: {faction: 'koutouzov', type: 'pilot'},
            516142: {faction: 'koutouzov', type: 'lieutenant'},
            516143: {faction: 'koutouzov', type: 'captain'},
            516144: {faction: 'koutouzov', type: 'colonel'},
            516145: {faction: 'koutouzov', type: 'warlord'},

            516147: {faction: 'manikshaw', type: 'trooper'},
            516148: {faction: 'manikshaw', type: 'nurse'},
            516149: {faction: 'manikshaw', type: 'radio'},
            516150: {faction: 'manikshaw', type: 'sniper'},
            516151: {faction: 'manikshaw', type: 'paratrooper'},
            516152: {faction: 'manikshaw', type: 'gunner'},
            516153: {faction: 'manikshaw', type: 'tank driver'},
            516154: {faction: 'manikshaw', type: 'pilot'},
            516155: {faction: 'manikshaw', type: 'lieutenant'},
            516156: {faction: 'manikshaw', type: 'captain'},
            516157: {faction: 'manikshaw', type: 'colonel'},
            516158: {faction: 'manikshaw', type: 'warlord'},

            516161: {faction: 'mikewallace', type: 'trooper'},
            516162: {faction: 'mikewallace', type: 'nurse'},
            516163: {faction: 'mikewallace', type: 'radio'},
            516164: {faction: 'mikewallace', type: 'sniper'},
            516165: {faction: 'mikewallace', type: 'paratrooper'},
            516166: {faction: 'mikewallace', type: 'gunner'},
            516167: {faction: 'mikewallace', type: 'tank driver'},
            516168: {faction: 'mikewallace', type: 'pilot'},
            516169: {faction: 'mikewallace', type: 'lieutenant'},
            516170: {faction: 'mikewallace', type: 'captain'},
            516171: {faction: 'mikewallace', type: 'colonel'},
            516172: {faction: 'mikewallace', type: 'warlord'},

            516173: {faction: 'napoleon', type: 'trooper'},
            516174: {faction: 'napoleon', type: 'nurse'},
            516175: {faction: 'napoleon', type: 'radio'},
            516176: {faction: 'napoleon', type: 'sniper'},
            516177: {faction: 'napoleon', type: 'paratrooper'},
            516178: {faction: 'napoleon', type: 'gunner'},
            516179: {faction: 'napoleon', type: 'tank driver'},
            516180: {faction: 'napoleon', type: 'pilot'},
            516181: {faction: 'napoleon', type: 'lieutenant'},
            516182: {faction: 'napoleon', type: 'captain'},
            516183: {faction: 'napoleon', type: 'colonel'},
            516184: {faction: 'napoleon', type: 'warlord'},

            516185: {faction: 'odanobunaga', type: 'trooper'},
            516186: {faction: 'odanobunaga', type: 'nurse'},
            516187: {faction: 'odanobunaga', type: 'radio'},
            516188: {faction: 'odanobunaga', type: 'sniper'},
            516189: {faction: 'odanobunaga', type: 'paratrooper'},
            516190: {faction: 'odanobunaga', type: 'gunner'},
            516191: {faction: 'odanobunaga', type: 'tank driver'},
            516192: {faction: 'odanobunaga', type: 'pilot'},
            516193: {faction: 'odanobunaga', type: 'lieutenant'},
            516194: {faction: 'odanobunaga', type: 'captain'},
            516195: {faction: 'odanobunaga', type: 'colonel'},
            516196: {faction: 'odanobunaga', type: 'warlord'},

            516203: {faction: 'patton', type: 'trooper'},
            516204: {faction: 'patton', type: 'nurse'},
            516205: {faction: 'patton', type: 'radio'},
            516206: {faction: 'patton', type: 'sniper'},
            516207: {faction: 'patton', type: 'paratrooper'},
            516208: {faction: 'patton', type: 'gunner'},
            516209: {faction: 'patton', type: 'tank driver'},
            516210: {faction: 'patton', type: 'pilot'},
            516211: {faction: 'patton', type: 'lieutenant'},
            516212: {faction: 'patton', type: 'captain'},
            516214: {faction: 'patton', type: 'colonel'},
            516215: {faction: 'patton', type: 'warlord'},

            516218: {faction: 'shakazulu', type: 'trooper'},
            516219: {faction: 'shakazulu', type: 'nurse'},
            516220: {faction: 'shakazulu', type: 'radio'},
            516221: {faction: 'shakazulu', type: 'sniper'},
            516222: {faction: 'shakazulu', type: 'paratrooper'},
            516223: {faction: 'shakazulu', type: 'gunner'},
            516224: {faction: 'shakazulu', type: 'tank driver'},
            516225: {faction: 'shakazulu', type: 'pilot'},
            516226: {faction: 'shakazulu', type: 'lieutenant'},
            516227: {faction: 'shakazulu', type: 'captain'},
            516228: {faction: 'shakazulu', type: 'colonel'},
            516229: {faction: 'shakazulu', type: 'warlord'},

            516230: {faction: 'shingen', type: 'trooper'},
            516231: {faction: 'shingen', type: 'nurse'},
            516232: {faction: 'shingen', type: 'radio'},
            516233: {faction: 'shingen', type: 'sniper'},
            516234: {faction: 'shingen', type: 'paratrooper'},
            516235: {faction: 'shingen', type: 'gunner'},
            516236: {faction: 'shingen', type: 'tank driver'},
            516237: {faction: 'shingen', type: 'pilot'},
            516238: {faction: 'shingen', type: 'lieutenant'},
            516239: {faction: 'shingen', type: 'captain'},
            516240: {faction: 'shingen', type: 'colonel'},
            516241: {faction: 'shingen', type: 'warlord'},

            516245: {faction: 'ulyssgrant', type: 'trooper'},
            516246: {faction: 'ulyssgrant', type: 'nurse'},
            516247: {faction: 'ulyssgrant', type: 'radio'},
            516248: {faction: 'ulyssgrant', type: 'sniper'},
            516249: {faction: 'ulyssgrant', type: 'paratrooper'},
            516250: {faction: 'ulyssgrant', type: 'gunner'},
            516251: {faction: 'ulyssgrant', type: 'tank driver'},
            516252: {faction: 'ulyssgrant', type: 'pilot'},
            516253: {faction: 'ulyssgrant', type: 'lieutenant'},
            516254: {faction: 'ulyssgrant', type: 'captain'},
            516255: {faction: 'ulyssgrant', type: 'colonel'},
            516256: {faction: 'ulyssgrant', type: 'warlord'},

            516257: {faction: 'wellington', type: 'trooper'},
            516258: {faction: 'wellington', type: 'nurse'},
            516259: {faction: 'wellington', type: 'radio'},
            516260: {faction: 'wellington', type: 'sniper'},
            516261: {faction: 'wellington', type: 'paratrooper'},
            516262: {faction: 'wellington', type: 'gunner'},
            516263: {faction: 'wellington', type: 'tank driver'},
            516264: {faction: 'wellington', type: 'pilot'},
            516265: {faction: 'wellington', type: 'lieutenant'},
            516266: {faction: 'wellington', type: 'captain'},
            516267: {faction: 'wellington', type: 'colonel'},
            516268: {faction: 'wellington', type: 'warlord'},

            516269: {faction: 'zhykov', type: 'trooper'},
            516270: {faction: 'zhykov', type: 'nurse'},
            516271: {faction: 'zhykov', type: 'radio'},
            516272: {faction: 'zhykov', type: 'sniper'},
            516273: {faction: 'zhykov', type: 'paratrooper'},
            516274: {faction: 'zhykov', type: 'gunner'},
            516275: {faction: 'zhykov', type: 'tank driver'},
            516276: {faction: 'zhykov', type: 'pilot'},
            516277: {faction: 'zhykov', type: 'lieutenant'},
            516278: {faction: 'zhykov', type: 'captain'},
            516279: {faction: 'zhykov', type: 'colonel'},
            516280: {faction: 'zhykov', type: 'warlord'},
        },

        'weaponTemplates':
        {
            530260: 'grenade',
            530261: 'machine gun',
            530262: 'bazooka',
            530263: 'artillery',
            530264: 'tank',
            530265: 'cruise missile',
            530266: 'jet',
            530267: 'bomber',
            530268: 'icbm',
        },
    
        'weaponTemplatesOld':
        {
            493555: 'grenade',
            493556: 'machine gun',
            493557: 'bazooka',
            493558: 'artillery',
            493559: 'tank',
            493560: 'cruise missile',
            493561: 'jet',
            493562: 'bomber',
            493563: 'icbm',
        },
    
        'soldierFirepower':
        {
            trooper: 2,
            nurse: 1,
            radio: 2,
            sniper: 2,
            paratrooper: 4,
            gunner: 2,
            'tank driver': 2,
            pilot: 3,
            lieutenant: 4,
            captain: 4,
            colonel: 6,
            warlord: 10
        },
        
        'soldierInitiative':
        {
            trooper: 1,
            nurse: 4,
            radio: 2,
            sniper: 4,
            paratrooper: 2,
            gunner: 2,
            'tank driver': 3,
            pilot: 4,
            lieutenant: 5,
            captain: 10,
            colonel: 15,
            warlord: 20
        },
        
        'weaponFirepower':
        {
            grenade: 2,
            'machine gun': 3,
            bazooka: 4,
            artillery: 5,
            tank: 10,
            'cruise missile': 12,
            jet: 15,
            bomber: 20,
            icbm: 30,
        },
    }
}