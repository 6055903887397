<template>
  <section id="our-roadmap" class="radar-wrap container container-vertical">
    <h1 class="title title-gradient">OUR ROADMAP</h1>
    <div class="content">
      <the-radar />
      <ul class="bullet-points">
        <li
          v-for="(point, i) in bulletPoints"
          :key="i"
          class="bullet-points__single"
          :style="{ left: point.left }"
          data-aos="fade-up"
          data-aos-easing="ease"
        >
          <div class="text">
            <div class="text__content">
              <span class="quartal">{{ point.quartal }}</span>
              <span class="divider"></span>
              <span class="data">{{ point.text }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import TheRadar from "./TheRadar.vue";
export default {
  name: "RoadMapSection",
  components: { TheRadar },
  data() {
    return {
      bulletPoints: [
        {
          quartal: "Q1 2022",
          text: 'Series 1 "Great Generals" NFT Drops',
          left: "-10rem",
        },
        {
          quartal: "Q2 2022",
          text: `Game Beta PVP Game: Win or lose your NFTs to your opponents
Token Mining “Win to Mine”
Token Staking & GameFi`,
          left: "-6rem",
        },
        {
          quartal: "Q3 2022",
          text: `Mobile version launch`,
          left: "-4rem",
        },
        {
          quartal: "Q4 2022",
          text: "Turn based mode - Squad leader",
          left: "-5rem",
        },
        {
          quartal: "Q2 2024",
          text: "MMO launch - Conquest",
          left: "-7rem",
        },
        {
          quartal: "Q3 2024",
          text: "Series 2 Drop",
          left: "-9rem",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.radar-wrap {
  background-image: url("../images/ourroadmap/bg-new.png");
  background-size: cover;
  width: 100%;
  position: relative;
  @include media-max(820px) {
    height: auto;
  }
  .content {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    @include media-max(1000px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      justify-content: center;
      .bullet-points {
        margin-top: 4rem;
        &__single {
          left: 0 !important;
          .text {
            .quartal {
              font-size: 18px !important;
            }
          }
        }
      }
    }
    .bullet-points {
      &__single {
        position: relative;
        .text {
          display: flex;
          display: inline-block;

          align-items: center;
          background-color: rgb(22,29,12);
          background: linear-gradient(90deg, rgba(22,29,12,1) 0%, rgba(20,38,38,1) 100%);
          padding: 1.5rem 2rem;
          border-radius: 500px;
          margin: 0.5rem 0;
          &__content {
            display: flex;
            align-items: center;
          }
          .quartal {
            color: $orange;
            font-size: 30px;
            white-space: nowrap;
          }
          .divider {
            display: inline-block;
            height: 30px;
            width: 2px;
            border-radius: 500px;
            background: $orange;
            box-shadow: $orange 0 0 5px 1px;
            margin: 0 1rem;
          }
          .data {
            display: inline-block;
          }
        }
      }
    }
  }
}
</style>