<template>
  <transition name="slide">
    <aside class="app-modal" v-if="isOpen">
      <div class="app-modal__body">
        <article class="app-modal__body__inner">
          <slot> </slot>
        </article>
        <button id="close-modal" @click="closeModal">
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.395703 2.3361C-0.129297 1.8111 -0.129297 0.93732 0.395703 0.393964C0.93906 -0.131036 1.79448 -0.131036 2.33784 0.393964L13.9919 12.0676L25.6655 0.393964C26.1905 -0.131036 27.0642 -0.131036 27.588 0.393964C28.1314 0.93732 28.1314 1.81232 27.588 2.3361L15.934 13.9913L27.588 25.6649C28.1314 26.1899 28.1314 27.0637 27.588 27.6071C27.063 28.1321 26.1892 28.1321 25.6655 27.6071L13.9919 15.9335L2.33784 27.6071C1.79448 28.1321 0.93906 28.1321 0.395703 27.6071C-0.129297 27.0637 -0.129297 26.1887 0.395703 25.6649L12.0497 13.9913L0.395703 2.3361Z"
              fill="#A0A0A0"
            />
          </svg>
        </button>
      </div>
    </aside>
  </transition>
</template>

<script>
export default {
  name: "AppModal",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.app-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  background: rgba($dark, 0.7);
  &__body {
    position: relative;
    width: 90%;
    height: auto;
    background: $dark;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    @include media-min(820px) {
      width: 800px;
      height: auto;
    }
    #close-modal {
      position: absolute;
      top: 2rem;
      right: 2rem;
      &:hover {
        opacity: 0.8;
      }
    }
    &__inner{
      height: 100%;
      width: 100%;
    }
  }
}
.slide-leave-active,
.slide-enter-active {
  transition: all 0.35s ease-in-out;
}
.slide-enter {
  opacity: 0;
 
  transform: translateY(-100%);
}
.slide-leave-to {
  opacity: 1;
  transform: translateY(-100%);
}
</style>