<template>
  <article class="app-input" :class="{ typing: inFocus, error: v.$invalid && v.$dirty }">
    <input
      @blur="onBlur"
      type="text"
      class="app-input__input"
      :value="value"
      @input="handleInput"
      :placeholder="placeholder"
    />
    <div class="error-message">
      <p
        v-if="v.$params.required && !v.required && v.$dirty"
      >
        This field is required
      </p>
      <p
        v-if="v.$params.email && !v.email && v.$dirty"
      >
        Email address is invalid
      </p>
    </div>
  </article>
</template>

<script>
export default {
  name: "AppInput",
  data() {
    return {
      inFocus: false,
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
     v: {
      type: Object,
      required: true,
      default: () => {},
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  methods: {
    handleInput(e) {
      this.inFocus = true;
      this.$emit("input", e.target.value);
    },
    onBlur() {
      this.inFocus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-input {
  width: 100%;
  height: 57px;
  border-radius: 10px;
  border: 1px solid white;
  transition: all $transition-fast;
  box-shadow: 0px 0px transparent;
  &.typing {
    border: 1px solid $orange;
    @include orange-glow;
  }
  &.error{
      border: 1px solid $red;
  }
  &.typing.error{
    @include error-glow;

  }
  &:hover {
    border: 1px solid $orange;
  }
  &__input {
    padding: 15px 25px;
    height: 100%;
    width: 100%;
    color: white;
    outline: none;

    @include placeholder {
      color: #a0a0a0;
      font-size: 1.05rem;
    }
  }

  .error-message{
      margin-top: 10px;
      color: $red;
  }
}
</style>