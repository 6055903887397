<template>
  <div class="l-privacy">

    <div class="l-header">
      <img
          class="l-header__img"
          src="../../images/LogoPinup_Fin_1024x636.jpg"
          alt="Pinupwarlords"
      />
    </div>
    <div class="l-privacy__ctn">
      <div class="l-privacy__back-btn">
        <router-link to="/" class="c-btn c-btn--back"/>
      </div>

      <h2>Terms of Use</h2>
      <br/>
      <p>
        • This User Agreement (“Agreement”) is made between Dragon Dream Digital Ltd (“Company”) or ( “DDD”) the licence
        owner of the game “Pinupwarlords” and you (“User”) Company and User are collectively referred to as the
        “Parties.”
      </p>
      <p>

        These Terms of Use ("Terms") apply to the game “Pinupwarlords” (‘the Game’) and the www.pinupwarlords.com
        website (the "Site"). The Game and the Site is the property of Dragon Dream Digital Ltd (hereinafter “DDD” or
        the “Company” or “us”), a Costa Rican Company with registration number 3-102-848701.
        <br/>
        By using the Site or installing the Game you accept these Terms without limitation. If you do not agree please
        do not use this Site or install any part of the Game. The Company has the right to amend these Terms by posting
        on the Site.
        <br/><br/>
      </p>
      <h3>Eligibility</h3>
      <br/>
      <p>
        You warrant that you:
        <br/><br/>

      </p>
      <ul>
        <li> are a natural person, at least 18 years old;</li>
        <li> have verified and determined that your use of the Site does not violate any law or regulation in any
          jurisdiction that applies to you;
        </li>
        <li>comply with all applicable national, federal, state, provincial, and local laws, statutes, regulations,
          rules, or directives (“Applicable Laws”) when buying, selling, and using Licensed Products;
        </li>
        <li>will not engage in any conduct that injures or may injure the business, reputation, or goodwill of the
          Company.
        </li>
      </ul>
      <p>
        <br/><br/>
      </p>
      <h3>IP rights</h3>
      <br/>
      <p>


        All intellectual property rights on the “Pinupwarlords”, the Site, the Game such as trademarks, copyright, all
        graphics, logos, text elements, images, software, know-how, and all other elements included in and deriving from
        the gameplay and virtual world featured in “Pinupwarlords”, including without limitation characters, locations,
        in-game names, and any virtual assets ("Virtual Items"), belong to Dragon Dream Digital Ltd or third-party
        affiliates ("Rights").

        <br/>
        <br/>
      </p>
      <h3>Grant of License</h3>
      <br/>
      <p>

        The Company grants you a non-exclusive, non-commercial, non-transferable, non-sublicensable, a worldwide license
        to use “Pinupwarlords” in accordance with these Terms.
        <br/><br/>
        You may not perform in-game services during your use of “Pinupwarlords” for any form of compensation outside of
        “Pinupwarlords” under this license.
        <br/><br/>
        You have the right to distribute video content of your personal gameplay on live streaming platforms or video
        sharing platforms while ensuring that such video content shall: (a) meet the conditions o the Terms and the
        additional obligations imposed by such platforms, (b) be free of charge and publicly accessible (c) contain a
        disclaimer that any views and opinions expressed in such video content are exclusively your own and do not
        represent views or opinions of the Company.
        <br/><br/>
        Dragon Dream Digital Ltd reserves the right to withdraw the above right with regard to existing or potential
        future content. In such case, you hereby agree to immediately remove from public access or alter, as instructed
        by the Company, upon the first request of the Company.
        <br/><br/>
        <br/>

      </p>
      <h3>Service</h3>
      <br/>
      <p>


        You can order packs of non-fungible tokens (“Packs”) for personal use by means of DDD's Service. The payment is
        processed by a third party for example Wax Wallet or Anchor wallet.
        <br/><br/>
        The chance of finding each type of item is displayed on the pack purchase screen (or on links to other sites).
        Packs, tokens, and items are licensed products.
        <br/><br/>
        You understand that DDD may monitor your Internet access location, without creating an obligation to do so, and
        may use techniques that are intended to block or restrict access from a jurisdiction in which participation in
        the Service is illegal or restricted.
        <br/><br/>
        Dragon Dream Digital Ltd further reserves the right, in its sole and absolute discretion, to refuse access to or
        use of the Service under the Terms if required by its third-party service providers, to the fullest extent
        allowed by Applicable Law.
        <br/><br/>
        You acknowledge that there are risks associated with utilizing Internet-based digital assets, products and
        websites including, but not limited to, the failure of hardware, software, Internet connections,
        telecommunications services, and the Blockchain. You acknowledge that the Company shall not be responsible for
        any communication failures, disruptions, errors, distortions, or delays you may experience when using our Site,
        or the Game however caused.
        <br/><br/>
        Responsibility for all Packs and other licensed products you obtain, including handling, transfer, loss, or
        other disposition lay with you.
        <br/><br/>
        Acquiring and storing Virtual Items for purposes of using our services involves various risks, in particular,
        that “Pinupwarlords” may not be able to launch some operations and continue developing its platform or
        community. Any interested person who is not in the position to accept nor to understand the risks associated
        with the activity, or any other risks as indicated in the Terms, should not acquire Virtual Items or use our
        Service.
        <br/><br/>


      </p>
      <h3>Payments and Fees</h3>
      <br/>
      <p>
        If you elect to purchase NFTs, any financial transactions that you engage in will be conducted solely through
        the Blockchain via an external wallet such as Wax wallet or Anchor wallet, or any other WAX blockchain
        compatible wallet. You acknowledge and understand that your transactions on the WAX platform are public and that
        your WAX address will be associated with these transactions.
        <br/><br/>
        You will be solely responsible to pay any fees, costs, deductions, expenses, taxes, and assessments (except
        taxes on our net income) now or hereafter claimed or imposed by any governmental authority (collectively,
        "Taxes") associated with your use of the Game. Accordingly, we will have no liability to you or to any third
        party for any claims or damages that may arise as a result of any transactions that you engage in with respect
        to NFTs.
        <br/><br/>
        Given the nature of a blockchain and minting process, all transactions and Licence purchases via NFTs on the
        site are irrevocable. No refund is possible.
        <br/><br/>
      </p>
      <h3>Restrictions and obligations</h3>
      <br/>
      <p>


        It is important to DDD that the Site and the Game be used safely and in accordance with the law. You agree that
        you will not use the Site and the Game to:
        <br/><br/>
      </p>
      <ul>
        <li>modify or copy, distribute any aspect of “Pinupwarlords”, including but not limited to the game's underlying
          rules and mechanics, the game's code, its user interface, and/or its operating principles;
        </li>
        <li>attempt to decompile or reverse engineer any software contained in “Pinupwarlords”;</li>
        <li>utilize multiple accounts to access or use “Pinupwarlords” in order to bypass any portion of the Terms or
          the “Pinupwarlords” gameplay mechanics.
        </li>
        <li>use any automatic software or "bots" to access or utilize “Pinupwarlords”.</li>
        <li>extract information from the Site and the Game using data collecting and extraction tools or software.</li>
        <li>distribute the information on the Site, and the Smart Contracts to any person or entity in any jurisdiction
          where such distribution would subject DDD to any registration requirement or prohibited by law.
        </li>
        <li>Engage or take part in any form of cheating. This rule includes but is not limited to: match-fixing, manipulating leaderboard and event scores, click spamming, and multi-accounting.
        </li>
        <li>Scam other individuals.
        </li>
        <li>Share exploits or exploitative bugs with other users. Please report all exploits and bugs to staff on the Discord Server so that we can fix them as soon as possible.
        </li>
      </ul>
      <p>

        <br/><br/>
      </p>
      <h3>Limitation of Liability; Disclaimers</h3>
      <br/>
      <p>


        NOTHING IN THESE TERMS WILL PREJUDICE THE STATUTORY RIGHTS THAT YOU MAY HAVE AS A CONSUMER OF THE SERVICES. SOME
        COUNTRIES, STATES, PROVINCES, AND OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE
        LIMITATION OF LIABILITY AS STATED, SO THE BELOW TERMS MAY NOT FULLY APPLY TO YOU BUT ONLY TO THE EXTENT
        PERMITTED BY THE LAWS OF SUCH JURISDICTION.
        <br/><br/>
        To the full extent permissible by law, Dragon Dream Digital Ltd will not be liable for any loss of profits or
        any indirect, incidental, punitive, special, or consequential damages arising out of or in connection with this
        these Terms.
        <br/><br/>
        Dragon Dream Digital Ltd disclaims any and all promises, representations, and warranties, whether express,
        implied, or statutory, including, but not limited to, fitness for a particular purpose, data accuracy, system
        integration, title, non-infringement, and any services provided by DDD are provided “as is” and “as available.”
        Except as expressly provided herein, you acknowledge that DDD makes no warranties under this agreement directly
        for the benefit of any end-user and that DDD’s obligations under this agreement are for the benefit of you only,
        and not for the benefit of any other person.
        <br/><br/>
        DDD does not make any representations or warranties that access to the services shall be continuous,
        uninterrupted, timely, or error-free. You understand that we cannot and do not guarantee or warrant that files
        available for downloading from the Site will be free of viruses or other destructive code. You are responsible
        for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus
        protection and accuracy of data input and output, and for maintaining a means external to our site for any
        reconstruction of any lost data.
        <br/><br/>
        DDD is not a financial institution and is currently not under the supervision of any financial supervisory
        authority. DDD does not provide any licensed financial services, such as investment services, fund management,
        or investment advice, and the Game, the Site do not offer, are not and shall not be construed as investment or
        financial products.
        <br/><br/>
        We do not warrant that Service is in compliance with all the laws and regulations in any localities, including
        your locality. It is your responsibility to ensure compliance with the laws and regulations of your locale.
        <br/><br/>
        “Pinupwarlords” non-fungible token:
        <br/><br/>
      </p>
      <ul>
        <li>is granted as a bonus when you play, not sold;</li>
        <li>has no material or physical form, or value (no one can make any claim or commitment as to its value);</li>
        <li>is non-refundable and cannot be exchanged for money;</li>
        <li>is not an investment, a security, or an investment product, or other financial or legal rights or equivalent
          rights, intellectual property rights, or any other form of participation in or relating to the Pinupwarlords
          ecosystem, the Company, and/or its service providers;
        </li>
        <li>is confers no title or ownership in the Company, the Distributor, or any of their respective affiliates;
        </li>
      </ul>
      <p>
        <br/><br/>
        Distributor (or their respective affiliate) holds the indirect contribution to the token mining. User will have
        no economic or legal right over or beneficial interest in these contributions or the assets of that entity after
        the token mining.
        <br/><br/>
        A secondary market or exchange for trading tokens may operate wholly independently of the Company, the
        Distributor, and the Pinupwarlords project ecosystem.
        <br/><br/>
        You must keep secret all credentials associated with “Pinupwarlords”. You are solely responsible for managing
        and maintaining the security of any information relating to such credentials and agree that DDD shall not be
        held responsible for any unauthorized access to “Pinupwarlords” or any resulting harm you may suffer.
        <br/><br/>

      </p>
      <h3>NFTs</h3>
      <br/>
      <p>

        “Pinupwarlords” allows its users to own certain specific virtual assets in form of non-fungible tokens issued on
        the WAX Blockchain or otherwise as Blockchain-based tokens. You understand and agree:
        <br/><br/>
      </p>
      <ul>
        <li>NFTs are earned through “Pinupwarlords” in accordance with the smart contracts’ functionality, as may be
          defined from time to time by DDD.
        </li>
        <li>You may purchase NFTs through external wallets. You acknowledge that given the nature of the blockchain all
          transactions are irrevocable and permanent. No reimbursement can be claimed.
        </li>
        <li>The Company may pause, cancel, or discontinue your transactions at its sole discretion without liability.
        </li>
        <li>DDD may specify at its sole discretion terms and conditions for purchase and purchase price from time to
          time.
        </li>
        <li>NFTs may be transferred within the gameplay of “Pinupwarlords”.</li>
      </ul>
      <p>
        <br/><br/>
        You understand and agree that NFT is subject to certain risks including without limitation:
        <br/><br/>
      </p>
      <ul>
        <li>Value and liquidity of the NFTs, are extremely volatile and may be subject to fluctuations. We make no
          representations or warranties that NFTs can effectively be resold;
        </li>
        <li>NFTs are subject to the risk of cyber-attacks, fraud, counterfeiting, and other technical difficulties;</li>
        <li>Legislative and regulatory changes or actions may adversely affect the use, transfer, and value of the
          NFTs;
        </li>
        <li>Your ability to purchase, manage, use sell, hold, and/or otherwise alienate NFTs is WAX dependent. You
          hereby agree to fully and solely bear for all costs, fees, deductions, or expenses.
        </li>
        <li>Other changes to the WAX platform could impact and/or impede your ability to purchase, hold, manage, use
          sell, or otherwise alienate NFTs.
        </li>
      </ul>
      <p>


        You understand and agree that you are solely responsible for determining the nature, potential value,
        suitability, and appropriateness of these risks for yourself. The Company does not give any advice or
        recommendations regarding the NFTs.
        <br/><br/>

      </p>
      <h3>Links to third-party websites</h3>
      <br/>
      <p>


        In using “Pinupwarlords”, you may view content or services provided by third parties, including links to web
        pages and services of such parties (“Third Party Content”). We have no control over any Third Party Content and
        have no responsibility for Third Party Content. We are not responsible or liable for any loss or damage of any
        sort incurred as a result of any such dealings and your use of Third Party Content is at your own risk.
        <br/><br/>

        You will defend, indemnify and hold Company, its affiliates and licensors, and its officers, directors,
        contractors, employees, agents, and representatives, harmless from and against all claims, actions, demands,
        liabilities, damages, losses, costs, and expenses (including legal fees) relating to or arising from (a) your
        use of the Site and the Game (b) your breach of these Terms (c) your violation of any rights of any other person
        or entity in connection with these Terms (d) third-party claims concerning these Terms or you thereof.
        <br/><br/>
        You will promptly notify us of any third-party claims, actions, or proceedings that you become aware of that
        involve the Company as a result of these Terms. The Company may participate with counsel of its own choosing in
        any proceedings described above. You will not agree to any settlement which imposes any obligation or liability
        for the Company without Company’s prior written consent.
        <br/><br/>

      </p>
      <h3>Termination</h3>
      <br/>
      <p>


        You acknowledge and accept that you may not be able to access, use, benefit from, transfer, and/or otherwise
        dispose of such NFTs and/or units of Coin following termination of this Agreement.
        <br/><br/>
        DDD has the right to withdraw or otherwise request the transfer to “Pinupwarlords” or such third party as DDD
        may determine within its sole discretion, of (a) all or specific NFTs and/or
        (b) all or specific units of Coin from the End User and to retransfer such NFTs, against such consideration (if
        any) without previous written notice, without liability to you, and on its own terms.
        <br/><br/>
        User shall retain ownership of NFTs and units of Coin if the DDD withdrawal right is not exercised in connection
        with a Termination of this agreement.
        <br/><br/>
        DDD is not responsible for any damage resulting from such loss of access nor any obligation to refund the
        purchase price paid for the relevant NFTs and/or units of Coin to the User.
        <br/><br/>

      </p>
      <h3>Governing Law</h3>
      <br/>
      <p>


        The rights and obligations of the parties hereunder and the interpretation of these Terms will be governed by
        the laws of Costa Rica without regard to choice-of-law rules.
        <br/><br/>
        If either party brings against the other party any proceeding arising out of these Terms, that party may bring
        proceedings only in the courts of Costa Rica and no other courts, and each party hereby submits to the exclusive
        jurisdiction of those courts for purposes of any such proceeding.
        <br/><br/>


      </p>
    </div>
    <AppFooter/>
  </div>
</template>

<script>
import AppFooter from '../../components/AppFooter.vue';

export default {
  name: "TermsPage",
  components: {
    AppFooter
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
</style>
