<template>
  <aside class="disclaimer-popup container" :class="{'hidden': isAccepted}">
    <div class="disclaimer-popup__inner" >
      <span class="disclaimer-popup__title">Disclaimer</span>
      <br/><br/><br/>
      <span>Please check the boxes below to confirm your agreement to the Pinup Warlords
        <router-link to="terms" class="link-normal"> Terms and Conditions.</router-link></span>
      <br/><br/>
      <Checkbox value="a"
                v-model="values"
                color="#F7841A"
                :size="20"
                class="disclaimer-popup__check"/>
      <span class="disclaimer-popup__text">I have read and understood and agree to the
        <router-link to="terms" class="link-normal"> Terms and Conditions.</router-link>
        Including all future amendments. Such agreement is irrevocable and will apply to all my uses of the site.</span>
      <br/><br/>
      <Checkbox value="b"
                v-model="values"
                color="#F7841A"
                :size="20"
                class="disclaimer-popup__check"/>
      <span class="disclaimer-popup__text">I understand that given the nature of the blockchain and<br/> Pinupwarlords being a fully onchain game
        all my transactions,<br/> purchases and actions are irreversible.</span>
      <br/><br/>
      <Checkbox value="c"
                v-model="values"
                color="#F7841A"
                :size="20"
                class="disclaimer-popup__check">I’m 18 years or older.</Checkbox>
      <br/><br/>
      <app-button @click="acceptDisclaimer" class="disclaimer-popup__button">
        CONFIRM
      </app-button>
    </div>
  </aside>
</template>

<script>
import AppButton from '../components/AppButton'
import Checkbox from 'vue-material-checkbox'
export default {
  name: "DisclaimerPopup",
  components: {
      AppButton,
    Checkbox,
  },
  data() {
      return {
          values: [],
          isAccepted: localStorage.getItem('disclaimer-accepted') ? JSON.parse(localStorage.getItem('disclaimer-accepted')) : false
      }
  },methods: {
    acceptDisclaimer() {
          if(this.values.includes("a") && this.values.includes("b") && this.values.includes("c")){
            this.isAccepted = true;
            localStorage.setItem('disclaimer-accepted', true);
          }
      }
  },
};
</script>

<style lang="scss" scoped>
.hidden{
    display: none
}
.disclaimer-popup{

  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;

  background: rgba(0, 0, 0, 0);

  &__inner{
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    width: 70%;
    //height: 70%;
    left: 30%;
    transform: translate(-20%, 0%);
    top: 10%;

    font-family: 'Chakra Petch';
    font-style: normal;
    font-size: 0.7rem;

    overflow: hidden;

    @include media-min(1280px) {
      width: 40%;
    }

    @include media-min(820px) {
      width: 60%;
    }

    @include media-min(490px) {
      font-size: 1em;
      font-weight: 400;
      line-height: 22px;
      top: 20%;
    }

    border: 1px solid #FFFFFF;
    border-radius: 20px;

    padding: 3rem;
    //display: flex;
    //align-items: center;
    justify-content: space-between;
    a{
        color: $orange;
        border-bottom: 1px solid $orange;
    }
  }
  &__title{
    /* Disclaimer */



    left: 50px;
    top: 50px;

    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    font-size: 1.2rem;
    /* identical to box height, or 39px */
    @include media-min(490px) {
      font-size: 1.6rem;
    }


    color: #F71A1A;
  }
  &__button{
    /* btn_1 */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 35px;
    gap: 10px;
    font-size: 0.7rem;
    transform: translate(-40%, 40%);

    @include media-min(490px) {
      font-size: 1rem;
      width: 100px;
      height: 52px;
      //transform: translate(-25%, 0%);
    }

    position: relative;
    height: 35px;
    width: 10%;
    left: 50%;

    /* orange_gradient */

    background: linear-gradient(94.83deg, #FE9D44 -22.28%, #F47B0B 140.99%);
    border-radius: 10px;
  }
  &__check{
    position: absolute;
    border-radius: 3px;
    width: 580px;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 3.2%, rgba(255,255,255,0) 3.2%, rgba(255,255,255,0) 100%);
  }
  &__text{
    position: relative;
    left: 30px;
  }
}

</style>