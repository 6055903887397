<template>
  <aside class="cookie-popup container" :class="{'hidden': isAccepted}">
    <div class="cookie-popup__inner">
      <span>We use cookies to ensure that we give you the best experience on our
        website. To learn more, go to the
        <router-link to="privacy-policy" class="link-normal"> Privacy Page </router-link></span>
      <app-button @click="acceptCookies">
        Accept
      </app-button>
    </div>
  </aside>
</template>

<script>
import AppButton from '../components/AppButton'
export default {
  name: "CookiePopup",
  components: {
      AppButton,
  },
  data() {
      return {
          isAccepted: localStorage.getItem('cookies-accepted') ? JSON.parse(localStorage.getItem('cookies-accepted')) : false 
      }
  },methods: {
      acceptCookies() {
          this.isAccepted = true;
          localStorage.setItem('cookies-accepted', true)
      }
  },
};
</script>

<style lang="scss" scoped>
.hidden{
    display: none
}
.cookie-popup{
    text-align: center;
    z-index: 10;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    background: rgba(0, 0, 0, 0.60);

    &__inner{
        width: 100%;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        a{
            color: $orange;
            border-bottom: 1px solid $orange;
        }
    }
}
</style>