import { UAL } from "universal-authenticator-library";
// import { Scatter } from 'ual-scatter';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { Wombat } from 'wombat-ual';

import config from '../config.js'
import constants from '../constants/index.js'
const appName = 'pinupwarlords.com';

let refSet = false;

function getPermission(user) {
    if (user.scatter) {
        return user.scatter.identity.accounts[0].authority;
    }
    if (user.requestPermission) {
        return user.requestPermission;
    }
    if (user.wallet && user.wallet.permissions) {
        return this.user.wallet.permissions;
    }
    return 'active';
}

export default class Wallet {
    constructor(chain) {
        this.user = null;
        this.chainId = config[chain].chainId;
        this.setChain(chain);
    }

    getUsername() {
        if (!this.user) {
            return null;
        }
        return this.user.accountName ? this.user.accountName : this.user.wallet.name;
    }

    async setChain(chain) {
        if (chain == this.chain) {
            return;
        }
        this.chain = chain;
        this.chainId = config[this.chain].chainId;

        const endpoint = config[chain].apiUri;
        const indexOfFirstColon = endpoint.indexOf(':');
        const indexOfSecondColon = endpoint.slice(indexOfFirstColon + 1).indexOf(':') + indexOfFirstColon + 1;

        const chains = [{
            chainId: this.chainId,
            rpcEndpoints: [{
                protocol: endpoint.slice(0, indexOfFirstColon),
                host: endpoint.slice(indexOfFirstColon + 3, indexOfSecondColon),
                port: endpoint.slice(indexOfSecondColon + 1),
            }]
        }];

        if (this.user) {
            await this.logout();
        }

        this.ual = new UAL(chains, this.dappName, [
            // new Scatter(chains, { appName }),
            new Anchor(chains, { appName }),
            new Wax(chains, { appName }),
            new Wombat(chains, { appName })
        ]);

        // this.ual.authenticators[0].walletName = 'scatter';
        this.ual.authenticators[0].walletName = 'anchor';
        this.ual.authenticators[1].walletName = 'wax';
        this.ual.authenticators[2].walletName = 'wombat';
    }

    async login(wallet) {
        if (this.user) {
            await this.logout();
        }

        this.wallet = wallet;
        console.log(wallet)
        this.authenticator = this.ual.authenticators.find(
            auth => auth.walletName.toLowerCase() == wallet.toLowerCase()
        );

        try {
            await this.authenticator.init();

            try {
                const users = await this.authenticator.login(null, 0, 0, 0);
                this.user = users[0];
                if (this.user) {
                    console.log(this.user);
                    if (this.user.scatter && this.user.scatter.identity.accounts[0].chainId != this.chainId) {
                        await this.logout();
                    } else {
                        localStorage.setItem('wallet', wallet);
                    }
                }
            } catch (error) {
                console.log(wallet, error);
                //this.loginMessage = `Error: ${error}`;
            }
        } catch (err) {
            console.log(err);
            /*let m = "Wallet unavailable";
            if (this.authenticator) {
                m = this.authenticator.getError() || err;
                m += `</br> <a target="_blank" href=${this.authenticator.getOnboardingLink()}>${this.authenticator.constructor.name
                    } Website</a>`;
            }
            this.loginMessage = m;*/
        }
    }

    async logout() {
        await this.authenticator.logout();
        this.user = null;
    }

    async setRef(ref) {
        this.ref = ref;
    }

    async buypacks(drop, amount) {
        let quantity = (Number(drop.listing_price.split(' ')[0]) * amount).toFixed(8) + ' WAX';
        const actions = [{
            account: constants.waxAccountName,
            name: "buyingpacks",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                user: this.getUsername(this.user)
            },
        },
        {
            account: constants.atomicdropsx,
            name: "assertdrop",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                assets_to_mint_to_assert: drop.assets_to_mint,
                drop_id: drop.drop_id,
                listing_price_to_assert: drop.listing_price,
                settlement_symbol_to_assert: drop.settlement_symbol
            },
        },
        {
            account: 'eosio.token',
            name: "transfer",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                from: this.getUsername(this.user),
                to: constants.atomicdropsx,
                quantity,
                memo: 'deposit'
            },
        },
        {
            account: constants.atomicdropsx,
            name: "claimdrop",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                claim_amount: amount,
                claimer: this.getUsername(this.user),
                country: "US",
                drop_id: drop.drop_id,
                intended_delphi_median: 0,
                referrer: "atomichub"
            },
        }];

        return await this.transaction(actions);
    };

    async buyammo(ammoPrice, amount) {
        let quantity = (Number(ammoPrice.split(' ')[0]) * amount).toFixed(8) + ' WAX';

        const actions = [{
            account: "eosio.token",
            name: "transfer",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                from: this.getUsername(this.user),
                to: constants.waxAccountName,
                quantity,
                memo: "buy ammo:" + amount.toString()
            },
        }];

        return await this.transaction(actions);
    };

    async buyweapons(weaponPrice, amount, type) {
        let quantity = (Number(weaponPrice.split(' ')[0]) * amount).toFixed(8) + ' WAX';

        const actions = [{
            account: "eosio.token",
            name: "transfer",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                from: this.getUsername(this.user),
                to: constants.waxAccountName,
                quantity,
                memo: "buy weapon " + type + " pack:" + amount.toString()
            },
        }];

        return await this.transaction(actions);
    };

    async unpack(assetId) {
        const actions = [{
            account: constants.atomicassets,
            name: "transfer",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                from: this.getUsername(this.user),
                to: constants.waxAccountName,
                asset_ids: [assetId],
                memo: "unpack"
            },
        }];

        return await this.transaction(actions);
    };

    async spinweapons(isAirforce) {
        const actions = [{
            account: constants.waxAccountName,
            name: "spinweapons",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                user: this.getUsername(this.user),
                is_airforce: isAirforce
            },
        }];

        return await this.transaction(actions);
    };

    async blendweapons(assetIds) {
        const actions = [{
            account: constants.atomicassets,
            name: "transfer",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                from: this.getUsername(this.user),
                to: constants.waxAccountName,
                asset_ids: assetIds,
                memo: "blend"
            },
        }];

        return await this.transaction(actions);
    };

    async battle(soldiers, staked, weapons) {
        const actions = [{
            account: constants.waxAccountName,
            name: "prepbattle",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                user: this.getUsername(this.user),
                soldiers,
                staked,
                weapons
            },
        },
        {
            account: constants.atomicassets,
            name: "transfer",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                from: this.getUsername(this.user),
                to: constants.waxAccountName,
                asset_ids: soldiers.concat(weapons).concat(staked),
                memo: 'battle'
            },
        },
        {
            account: constants.waxAccountName,
            name: "findopponent",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                user: this.getUsername(this.user),
            },
        }];

        return await this.transaction(actions);
    };

    async getreferral() {
        const actions = [{
            account: constants.waxAccountName,
            name: "getreferral",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                user: this.getUsername(this.user)
            },
        }];

        return await this.transaction(actions);
    };

    async questreward() {
        const actions = [{
            account: constants.waxAccountName,
            name: "questreward",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                user: this.getUsername(this.user)
            },
        }];

        return await this.transaction(actions);
    };

    async stakeheels(amount) {
        let quantity = (Number(amount)).toFixed(4) + ' ' + constants.heelsTokenName;

        const actions = [{
            account: constants.heelsAccountName,
            name: "transfer",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                from: this.getUsername(this.user),
                to: constants.waxAccountName,
                quantity,
                memo: "stake"
            },
        }];

        return await this.transaction(actions);
    };

    async unstake(amount) {
        amount = (Number(amount)).toFixed(4) + ' ' + constants.heelsTokenName;

        const actions = [{
            account: constants.waxAccountName,
            name: "unstake",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                user: this.getUsername(this.user),
                amount
            },
        }];

        return await this.transaction(actions);
    };

    async withdraw() {
        const actions = [{
            account: constants.waxAccountName,
            name: "withdraw",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                user: this.getUsername(this.user)
            },
        }];

        return await this.transaction(actions);
    };

    async tokenbonus() {
        const actions = [{
            account: constants.waxAccountName,
            name: "tokenbonus",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                user: this.getUsername(this.user)
            },
        }];

        return await this.transaction(actions);
    };

    async collectbonus() {
        const actions = [{
            account: constants.waxAccountName,
            name: "collectbonus",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                user: this.getUsername(this.user)
            },
        }];

        return await this.transaction(actions);
    };

    async staketeam(soldiers) {
        const actions = [
        {
            account: constants.atomicassets,
            name: "transfer",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                from: this.getUsername(this.user),
                to: constants.waxAccountName,
                asset_ids: soldiers,
                memo: 'stake'
            },
        }];

        return await this.transaction(actions);
    };

    async unstaketeam(faction) {
        const actions = [{
            account: constants.waxAccountName,
            name: "unstaketeam",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                user: this.getUsername(this.user),
                faction_name: faction
            },
        }];

        return await this.transaction(actions);
    };

    async startacademy(soldiers, experience, cost, faction) {
        let quantity = cost.toFixed(4) + ' ' + constants.heelsTokenName;
        const actions = [
        {
            account: constants.atomicassets,
            name: "transfer",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                from: this.getUsername(this.user),
                to: constants.waxAccountName,
                asset_ids: soldiers,
                memo: 'academy:' + experience.toString()
            },
        },
        {
            account: constants.heelsAccountName,
            name: "transfer",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                from: this.getUsername(this.user),
                to: constants.waxAccountName,
                quantity,
                memo: "academy:" + faction
            },
        }];

        return await this.transaction(actions);
    };

    async finishacademy(faction) {
        const actions = [{
            account: constants.waxAccountName,
            name: "endacademy",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                user: this.getUsername(this.user),
                faction_name: faction
            },
        }];

        return await this.transaction(actions);
    };

    async transaction(actions) {
        let doRef = false;
        if(this.ref && !refSet) {
            doRef = true;
        }
        try {
            if(doRef) {
                actions.unshift(
                    {
                        account: constants.waxAccountName,
                        name: "setreferrer",
                        authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
                        data: {
                            user: this.getUsername(this.user),
                            referrer: this.ref
                        },
                    }
                );
            }
            console.log(actions);
            let t = await this.user.signTransaction({ actions }, { broadcast: true, blocksBehind: 3, expireSeconds: 120, });
            if(t && doRef) {
                refSet = true;
            }
            return t.transactionId;
        } catch (e) {
            console.log(e);
        }
    }


    async startsquadgame(betAmount, currency, weaponId) {
        const actions = [
            {
                account: constants.waxAccountName,
                authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
                name: "prepsquadldr",
                data: {
                    user: this.getUsername(this.user),
                    wager: (currency.toLowerCase().includes("wax")?betAmount.toFixed(8):betAmount.toFixed(4))+" "+currency
                },
            }
        ];
        if(betAmount >0){

            let quantity =(currency.toLowerCase().includes("wax")?betAmount.toFixed(8):betAmount.toFixed(4)) + ' ' +currency;
            actions.push({
                account: currency.toLowerCase().includes("wax")?"eosio.token":constants.heelsAccountName,
                authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
                name: "transfer",
                data: {
                    from: this.getUsername(this.user),
                    to: constants.waxAccountName,
                    quantity,
                    memo: 'squadleaders wager'
                },
            })
        }
        if(weaponId){
            actions.push({
                account: "atomicassets",
                authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
                name: "transfer",
                data: {
                    from: this.getUsername(this.user),
                    to: constants.waxAccountName,
                    asset_ids: [weaponId],
                    memo: 'squadleaders battle'
                },
            })
        }
        return await this.transaction(actions);
    };

    async unfreeze(assetId) {
        const actions = [
            {
                account: constants.waxAccountName,
                authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
                name: "unfreeze",
                data: {
                    user: this.getUsername(this.user),
                    soldiers:assetId
                },
            }
        ];
        return await this.transaction(actions);
    };
}