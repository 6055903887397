<template>
  <section id="buy-cards" class="buy-cards container">
    <article class="buy-cards__first">
      <h1 class="title title-gradient">BUY CARDS</h1>
      <div
        class="buy-cards__cards"
        data-aos="fade-up"
        data-aos-easing="ease"
        data-aos-delay="300"
      >
        <img
          src="@/images/buycards/Campaign_Pack_Fin.png"
          alt="Campaign pack of cards"
          class="image-1"
        />
        <img
          src="@/images/buycards/Squad_Pack_Fin.png"
          alt="Squad pack of cards"
          class="image-2"
        />
      </div>
    </article>
    <article class="buy-cards__second">
      <p class="paragraph">
        Factions are limited edition NFTs and will never be reprinted ever
        again. Become a collector and earn higher rewards from building up your
        collection.
      </p>
      <div class="buy-cards__icons">
        <div
          v-for="(icon, i) in icons"
          :key="icon.name"
          class="buy-cards__icons__single"
          data-aos="fade-up"
          data-aos-easing="ease"
          :data-aos-delay="i * 100"
        >
          <div class="img-container">
            <img alt="Squad pack of cards" :src="icon.url" />
          </div>
          <div>
            <p>{{ icon.title }}</p>
            <p class="font-crimson">
              {{ icon.percent }}
            </p>
          </div>
        </div>
      </div>
      <app-button @click="openAtomicDrop" class="buy-cards__btn">
        BUY NOW
      </app-button>
    </article>
  </section>
</template>

<script>
import AppButton from "@/components/AppButton.vue";
import constants from '../constants/index.js'

export default {
  name: "BuyCardsSection",
  components: {
    AppButton,
  },
  data() {
    return {
      icons: [
        {
          url: "../images/buycards/common.png",
          title: "Common",
          percent: "32.83%",
        },
        {
          url: "../images/buycards/uncommon.png",
          title: "Uncommon",
          percent: "16.25%",
        },
        {
          url: "../images/buycards/scarce.png",
          title: "Scarce",
          percent: "8.13%",
        },
        {
          url: "../images/buycards/rare.png",
          title: "Rare",
          percent: "4.88%",
        },
        {
          url: "../images/buycards/super_rare.png",
          title: "Super Rare",
          percent: "1.63%",
        },
        {
          url: "../images/buycards/epic.png",
          title: "Epic",
          percent: "1.22%",
        },
        {
          url: "../images/buycards/legend.png",
          title: "Legend",
          percent: "0.81%",
        },
        {
          url: "../images/buycards/myth.png",
          title: "Myth",
          percent: "0.14%",
        },
      ],
    };
  },
  methods: {
    openAtomicDrop() {
      var url = 'https://wax.atomichub.io/drops/';
      if(constants.squadDropId) {
        url += constants.squadDropId;
      }
      if(constants.campaignDropId) {
        if(constants.squadDropId) {
          url += '+';
        }
        url += constants.campaignDropId;
      }
      window.open(url, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.buy-cards {
  padding-top: 3rem;
  padding-bottom: 2rem;
  width: 100%;
  display: grid;
  background: $green-dark;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  position: relative;
  background: url("../images/buycards/bg.png") no-repeat bottom center/cover;

  @include media-min(820px) {
    height: 100vh;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
  }

  &__first {
    height: 600px;
    @include media-min(820px) {
      height: 100%;
    }
  }
  &__second {
    position: relative;
    z-index: 1;
  }
  &__cards {
    height: 73%;
    @include media-min(820px) {
      height: 72%;
    }
    width: 100%;
    position: relative;
    z-index: 1;

    .image-1 {
      left: 0;
      transform: scale(0.7);
      transform-origin: top left;
      &:hover {
        transform: scale(0.7) translateY(-20px);
      }
    }
    .image-2 {
      top: 50px;
      right: 60px;
      transform: translate(0);
      &:hover {
        transform: translateY(-20px);
      }
    }
    img {
      position: absolute;
      width: auto;
      height: 100%;
      object-fit: contain;
      transition: 0.2s all ease-in-out;
    }
  }

  &__icons {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @include media-min(820px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    margin: 2rem 0;
    gap: 2rem 0;

    &__single {
      display: flex;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: -0.02em;
      .img-container {
        width: 25px;
        height: 50px;
        margin-top: 0.3rem;
        margin-right: 0.5rem;
        img {
          height: 100%;
          width: auto;
        }
      }
      p:first-child {
        font-weight: bold;
        font-size: 15px;
        line-height: 22.5px;
        color: $orange;
        margin-bottom: 0.3rem;
      }
    }
  }

  &__btn {
    position: relative;
  }
}

</style>