<template>
  <section id="the-cards" class="cards-wrap container">
    <article class="cards-wrap__text container-vertical">
      <h1 class="title title-gradient">THE CARDS</h1>
      <p class="bold-paragraph">
        Factions are made of Soldiers with different classes and officers
      </p>
      <ul class="bullet-points paragraph ligh-paragraph">
        <li>
          Each faction is a limited edition and will come from a total of 12
          classes, all hand drawn
        </li>
        <li>
          The more complete factions you own , the higher "collector level"
          you’ll reach, therefore increasing your overall passive income
        </li>
        <li>
          Each NFT has its own combat experience logged onto the blockchain,
          which grows independently from its owner after each battle
        </li>
        <li>Series 1 NFTs theme is "Great Generals"</li>
      </ul>
    </article>
    <div class="cards-wrap__cards">
      <img
        id="card-img-1"
        src="../images/thecards/PARATROOPER.png"
        alt="Paratrooper card"
      />
      <img
        id="card-img-2"
        src="../images/thecards/COLONEL.png"
        alt="Colonel card"
      />
      <img
        id="card-img-3"
        src="../images/thecards/GUNNER.png"
        alt="Gunner card"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "CardsSection",
};
</script>

<style lang="scss" scoped>
.cards-wrap {
  background-image: url("../images/thecards/background.png");
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  @include media-min(820px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  &__text {
    overflow-x: visible;
    width: 100%;

    .bullet-points {
      padding-left: 1.5rem;
      li {
        list-style-image: url("../images/star.png");
        margin-top: 1.5rem;
      }
    }

    @include media-min(820px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    > p:first-of-type {
      margin-bottom: 2rem;
    }
  }
  &__cards {
    height: 400px;
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    img {
      transition: all ease-in-out 0.2s;
      height: auto;
      width: 100%;
    }

    @include media-min(820px) {
      img {
        position: absolute;
      }
    }

    @include media-min(820px) {
      height: 100%;
      width: 100%;
      #card-img-1 {
        top: 4rem;
        transform: scale(0.4) translateY(0);
        transform-origin: top left;
        &:hover {
          transform: scale(0.4) translateY(-20px);
        }
      }
      #card-img-2 {
        top: 3%;
        right: 5rem;
        z-index: 1;
        transform: scale(0.7) translateY(0);
        &:hover {
          transform: scale(0.7) translateY(-20px);
        }
      }
      #card-img-3 {
        right: -8rem;
        bottom: -10rem;
        transform: scale(0.6) translateY(0);
        &:hover {
          transform: scale(0.6) translateY(-20px);
        }
      }
    }
  }
}
</style>