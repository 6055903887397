<template>
  <div id="app">
    <router-view />
    <cookie-popup />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CookiePopup from '@/components/CookiePopup.vue'

export default {
  name: "App",
  components: {
    CookiePopup,
  },
};
</script>

<style lang="scss">
@import "./assets/styles/main";
</style>
