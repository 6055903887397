<template>
  <div class="the-radar-container">
    <div id="the-radar" class="the-radar">
      <div id="the-radar-line" class="the-radar__line"></div>
      <img
        class="the-radar__circles"
        src="../images/ourroadmap/circles.png"
        alt="radar graphic to make it prettier"
      />
      <the-radar-pulse />
      <the-radar-pulse />
      <the-radar-pulse />
      <the-radar-pulse />
      <the-radar-pulse />
    </div>
    <img
      src="../images/ourroadmap/circle.png"
      alt="Another radar graphic to make it prettier"
      class="addition"
    />
  </div>
</template>

<script>
import TheRadarPulse from "./TheRadarPulse.vue";
export default {
  name: "TheRadar",
  components: { TheRadarPulse },
};
</script>

<style lang="scss" scoped>
.the-radar-container {
  position: relative;
  height: 30vw;
  width: 30vw;
  background: url("../images/ourroadmap/outer_circle.png") no-repeat center
    center/contain;
  display: flex;
  justify-content: center;
  align-items: center;
  .the-radar {
    position: relative;
    height: 90%;
    width: 90%;
    border-radius: 50%;
    background: black url("../images/ourroadmap/map.png") no-repeat 50% -55px / cover;
    overflow: hidden;
    &__line {
      height: 50%;
      width: 25%;
      border-left: 2px solid green;
      position: absolute;
      top: 0;
      left: 50%;
      clip-path: polygon(0 0, 0% 100%, 100% 0);
      background: linear-gradient(290deg, transparent 48.06%, #4cb648 116.36%);
      //   background: linear-gradient(285deg, transparent 38.06%, #4cb648 94.36%);
      transform: rotate(0deg);
      transform-origin: bottom left;
      animation: rotate-line infinite 4s linear;
    }

    &__circles {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-fit: cover;
    }
  }

  .addition {
    position: absolute;
    height: 114%;
    width: 114%;
  }
}

@keyframes rotate-line {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>