<template>
  <svg
    width="30"
    height="29"
    viewBox="0 0 30 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1003_467)">
      <path
        d="M15 5.50098L17.2451 12.4108H24.5106L18.6327 16.6813L20.8779 23.5911L15 19.3206L9.12215 23.5911L11.3673 16.6813L5.48944 12.4108H12.7549L15 5.50098Z"
        fill="#F7841A"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1003_467"
        x="0.489258"
        y="0.500977"
        width="29.0215"
        height="28.0903"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.968627 0 0 0 0 0.517647 0 0 0 0 0.101961 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1003_467"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1003_467"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "StarIcon",
};
</script>

<style lang="scss" scoped>
</style>