import { Serialize, JsonRpc } from 'eosjs';
import config from '../config.js'
import constants from '../constants/index.js'

// https://validate.eosnation.io/wax/reports/endpoints.html
const atomicAssetsApis = ['https://wax.api.atomicassets.io/atomicassets/v1/',
                          'https://wax-aa.eu.eosamsterdam.net/atomicassets/v1/',
                          'https://atomic.wax.eosrio.io/atomicassets/v1/'
                        ];

export default class Eos {

    constructor(chain) {
        this.setChain(chain);
    }

    setChain(chain) {
        this.chain = chain;
        this.rpc = new JsonRpc(config[chain].apiUri);
    }

    async getWaxBalance(userId) {
        let result = await this.rpc.get_currency_balance("eosio.token", userId, "WAX");
        if (result.length) {
            return result[0];
        }
        return 0
    }

    async getHeelsBalance(userId) {
        let result = await this.rpc.get_currency_balance(constants.heelsAccountName, userId, constants.heelsTokenName);
        if (result.length) {
            return result[0];
        }
        return 0
    }

    async getNumberOfPacks(userId) {
        let templates = await this.getTemplates(userId);

        let packs = {
            campaign: templates.hasOwnProperty(constants.campaignTemplateId)? templates[constants.campaignTemplateId] : 0,
            squad: templates.hasOwnProperty(constants.squadTemplateId)? templates[constants.squadTemplateId] : 0
        }

        return packs;
    }

    async getPrices() {
        let pricesTable = await this.getTableRows(constants.waxAccountName, constants.waxAccountName, 'price');
        if (pricesTable.rows.length === 0) {
            return {};
        } else {
            return pricesTable.rows[0];
        }
    }

    async getAmmo(userId) {
        let ammoTable = await this.getTableRows(constants.waxAccountName, constants.waxAccountName, 'ammo', userId, userId);
        if (ammoTable.rows.length === 0) {
            return 0;
        } else {
            return ammoTable.rows[0].num_ammo;
        }
    }

    async getCredits(userId) {
        let creditsTable = await this.getTableRows(constants.waxAccountName, constants.waxAccountName, 'credit', userId, userId);
        if (creditsTable.rows.length === 0) {
            return { army: 0, airforce: 0 };
        } else {
            return { army: creditsTable.rows[0].num_army_credits, airforce: creditsTable.rows[0].num_airforce_credits };
        }
    }

    async getTemplates(userId) {
        let response = await this.request('accounts/' + userId + '/' + constants.collectionName);

        let templates = {};

        if(response.success && response.data.templates) {
            for(let template of response.data.templates) {
                templates[template.template_id] = Number(template.assets);
            }
        }

        return templates;
    }

    async getWeaponTemplates(userId) {
        let response = await this.request('accounts/' + userId + '/' + constants.collectionName);

        let templates = {};

        if(response.success && response.data.templates) {
            for(let template of response.data.templates) {
                if(constants.weaponTemplates.hasOwnProperty(template.template_id)) {
                    if(templates[constants.weaponTemplates[template.template_id]]) {
                        templates[constants.weaponTemplates[template.template_id]] += Number(template.assets);
                    } else {
                        templates[constants.weaponTemplates[template.template_id]] = Number(template.assets);
                    }
                }
                else if(constants.weaponTemplatesOld.hasOwnProperty(template.template_id)) {
                    if(templates[constants.weaponTemplatesOld[template.template_id]]) {
                        templates[constants.weaponTemplatesOld[template.template_id]] += Number(template.assets);
                    } else {
                        templates[constants.weaponTemplatesOld[template.template_id]] = Number(template.assets);
                    }
                }
            }
        }

        for(let weaponTemplate of Object.keys(constants.weaponTemplates)) {
            if(!templates.hasOwnProperty(constants.weaponTemplates[weaponTemplate])) {
                templates[constants.weaponTemplates[weaponTemplate]] = 0;
            }
        }

        return templates;
    }

    async getSoldierAssets(userId, addInitiative=false) {
        let data = [];
        let page = 1;

        let response = await this.request('assets?collection_name=' + constants.collectionName + '&schema_name=soldier&owner=' + userId + '&page=' + page.toString() + '&limit=1000&order=asc&sort=name');
        while(response.success && response.data && response.data.length > 0) {
            page++;
            data = data.concat(response.data);

            response = await this.request('assets?collection_name=' + constants.collectionName + '&schema_name=soldier&owner=' + userId + '&page=' + page.toString() + '&limit=1000&order=asc&sort=name');
        }

        let soldiers = {};
        let assets = {};

        for(let soldier of data) {
            let template_id = soldier.template.template_id;
            let faction = constants.templates[template_id].faction;
            if(!soldiers.hasOwnProperty(faction)) {
                soldiers[faction] = {soldiers:{}};
            }
            let type = soldier.template.immutable_data.class.toLowerCase();
            if(!soldiers[faction].soldiers.hasOwnProperty(type)) {
                soldiers[faction].soldiers[type] = [];
            }
            if(!addInitiative) {
                soldiers[faction].soldiers[type].push({
                    asset_id: soldier.asset_id,
                    template_mint: soldier.template_mint,
                    level: soldier.mutable_data.level,
                    experience: soldier.mutable_data.experience
                });
                assets[soldier.asset_id] = {faction, type, level: soldier.mutable_data.level, experience: soldier.mutable_data.experience};
            }else {
                let initiative = constants.soldierInitiative[type] + (soldier.mutable_data.level-1) *
                    ((type==="captain" || type === "lieutenant")?2:
                        (type==="colonel")?3:
                            (type==="warlord")?5:
                                1)

                soldiers[faction].soldiers[type].push({
                    asset_id: soldier.asset_id,
                    template_mint: soldier.template_mint,
                    level: soldier.mutable_data.level,
                    experience: soldier.mutable_data.experience,
                    initiative: initiative
                });
                assets[soldier.asset_id] = {
                    faction,
                    type,
                    level: soldier.mutable_data.level,
                    experience: soldier.mutable_data.experience,
                    initiative: initiative
                };
            }
        }

        return { soldiers, assets };
    }

    async getPackAssetIds(templateId, userId) {
        let response = await this.request('assets?collection_name=' + constants.collectionName + '&schema_name=pack&template_id=' + templateId + '&owner=' + userId + '&page=1&limit=1&order=desc&sort=minted');

        let packs = [];
        if(response.success && response.data && response.data.length) {
            packs = response.data.map(asset => asset.asset_id);
        }

        return packs;
    }

    async getHighestMintWeaponsAssetId(weapons, userId) {
        let assetIds = [];
        for(let weapon of weapons)
        {
            let templateId, templateIdOld;
            for(let weaponTemplate of Object.keys(constants.weaponTemplates)) {
              if(weapon == constants.weaponTemplates[weaponTemplate]) {
                templateId = weaponTemplate;
                break;
              }
            }
            for(let weaponTemplate of Object.keys(constants.weaponTemplatesOld)) {
              if(weapon == constants.weaponTemplatesOld[weaponTemplate]) {
                templateIdOld = weaponTemplate;
                break;
              }
            }

            let response = await this.request('assets?collection_name=' + constants.collectionName + '&schema_name=weapon&template_id=' + templateIdOld + '&owner=' + userId + '&page=1&limit=1&order=desc&sort=minted');
            if(response.success && response.data && response.data.length) {
                assetIds.push(response.data[0].asset_id);
            } else {
                response = await this.request('assets?collection_name=' + constants.collectionName + '&schema_name=weapon&template_id=' + templateId + '&owner=' + userId + '&page=1&limit=1&order=desc&sort=minted');
                if(response.success && response.data && response.data.length) {
                    assetIds.push(response.data[0].asset_id);
                }
            }
        }

        return assetIds;
    }

    async getBlendWeaponAssetIds(weapon, amount, userId, blended) {
        let assetIds = [];
        let templateId, templateIdOld;
        for(let weaponTemplate of Object.keys(constants.weaponTemplates)) {
            if(weapon == constants.weaponTemplates[weaponTemplate]) {
            templateId = weaponTemplate;
            break;
            }
        }
        for(let weaponTemplate of Object.keys(constants.weaponTemplatesOld)) {
            if(weapon == constants.weaponTemplatesOld[weaponTemplate]) {
            templateIdOld = weaponTemplate;
            break;
            }
        }

        let response = await this.request('assets?collection_name=' + constants.collectionName + '&schema_name=weapon&template_id=' + templateIdOld + '&owner=' + userId + '&page=1&limit=' + (amount + blended.length).toString() + '&order=desc&sort=minted');
        if(response.success && response.data && response.data.length) {
            for(let i = 0; i<response.data.length; i++) {
                if(assetIds.length < amount) {
                    if(!blended.includes(response.data[i].asset_id)) {
                        assetIds.push(response.data[i].asset_id);
                    }
                } else {
                    break;
                }
            }
        }
        if(assetIds.length < amount) {
            response = await this.request('assets?collection_name=' + constants.collectionName + '&schema_name=weapon&template_id=' + templateId + '&owner=' + userId + '&page=1&limit=' + (amount + blended.length).toString() + '&order=desc&sort=minted');
            if(response.success && response.data && response.data.length) {
                for(let i = 0; i<response.data.length; i++) {
                    if(assetIds.length < amount) {
                        if(!blended.includes(response.data[i].asset_id)) {
                            assetIds.push(response.data[i].asset_id);
                        }
                    } else {
                        break;
                    }
                }
            }
        }
        return assetIds;
    }

    async getDrop(dropId) {
        let dropsTable = await this.getTableRows(constants.atomicdropsx, constants.atomicdropsx, 'drops', dropId, dropId);
        if (dropsTable.rows.length === 0) {
            return {max_claimable: 0, current_claimed: 0, listing_price: "0.00000000 WAX"};
        } else {
            return dropsTable.rows[0];
        }
    }

    async getBattle(userId) {
        let battleTable = await this.getTableRows(constants.waxAccountName, constants.waxAccountName, 'battle', userId, userId);
        if (battleTable.rows.length === 0) {
            return null;
        } else {
            return battleTable.rows[0];
        }
    }

    async getRecentBattles() {
        let battleTable = await this.getTableRows(constants.waxAccountName, constants.waxAccountName, 'recentbattle');
        return battleTable.rows;
    }

    async getReferral(userId) {
        let referralTable = await this.getTableRows(constants.waxAccountName, constants.waxAccountName, 'referral', userId, userId);
        if (referralTable.rows.length === 0) {
            return null;
        } else {
            return referralTable.rows[0];
        }
    }

    async getUserStats(userId) {
        let userStatsTable = await this.getTableRows(constants.waxAccountName, constants.waxAccountName, 'userstats', userId, userId);
        if (userStatsTable.rows.length === 0) {
            return null;
        } else {
            return userStatsTable.rows[0];
        }
    }

    async getStaked(userId) {
        let stakedTable = await this.getTableRows(constants.waxAccountName, constants.waxAccountName, 'staked', userId, userId);
        if (stakedTable.rows.length === 0) {
            return null;
        } else {
            return stakedTable.rows[0];
        }
    }

    async getDailyQuest(userId) {
        let dailyQuestTable = await this.getTableRows(constants.waxAccountName, constants.waxAccountName, 'dailyquest', userId, userId);
        if (dailyQuestTable.rows.length === 0) {
            return null;
        } else {
            return dailyQuestTable.rows[0];
        }
    }

    async getPools() {
        let poolsTable = await this.getTableRows(constants.waxAccountName, constants.waxAccountName, 'pools');
        if (poolsTable.rows.length === 0) {
            return null;
        } else {
            return poolsTable.rows[0];
        }
    }

    async getLeaderInfo() {
        let leaderInfoTable = await this.getTableRows(constants.waxAccountName, constants.waxAccountName, 'leaderinfo');
        if (leaderInfoTable.rows.length === 0) {
            return null;
        } else {
            return leaderInfoTable.rows[0];
        }
    }

    async getStakeInfo() {
        let stakeInfoTable = await this.getTableRows(constants.waxAccountName, constants.waxAccountName, 'stakeinfo');
        if (stakeInfoTable.rows.length === 0) {
            return null;
        } else {
            return stakeInfoTable.rows[0];
        }
    }

    async getCollectInfo() {
        let collectInfoTable = await this.getTableRows(constants.waxAccountName, constants.waxAccountName, 'collectinfo');
        if (collectInfoTable.rows.length === 0) {
            return {};
        } else {
            return collectInfoTable.rows[0];
        }
    }

    async getCollector(userId) {
        let collectorTable = await this.getTableRows(constants.waxAccountName, constants.waxAccountName, 'collector', userId, userId);
        if (collectorTable.rows.length === 0) {
            return null;
        } else {
            return collectorTable.rows[0];
        }
    }

    async getAcademy(userId) {
        let academyTable = await this.getTableRows(constants.waxAccountName, userId, 'academy');
        let academy = {};
        for(let row of academyTable.rows)
        {
            academy[row.faction_name] = {};
            academy[row.faction_name].instructors = [];
            for(let i = 0; i<row.instructors.length; i++) {
                academy[row.faction_name].instructors.push({asset_id: row.instructors[i], level: row.instructor_levels[i], experience: row.instructor_experience[i], template_mint: '1', type: constants.templates[row.instructor_templates[i]].type});
            }
            academy[row.faction_name].cadets = [];
            for(let i = 0; i<row.cadets.length; i++) {
                academy[row.faction_name].cadets.push({asset_id: row.cadets[i], level: row.cadet_levels[i], experience: row.cadet_experience[i], template_mint: '1', type: constants.templates[row.cadet_templates[i]].type});
            }

            let secondsUntilFinish = 0;
            const unixTime = Math.floor(Date.now() / 1000);
            const unlockTime = row.unlock_time;
            secondsUntilFinish = Math.max(0, unlockTime - unixTime);

            academy[row.faction_name].secondsUntilFinish = secondsUntilFinish;
        }
        return academy;
    }

    async getLeaderboard(week, userId = null) {
        let weekBuffer = new Serialize.SerialBuffer();
        weekBuffer.pushNumberAsUint64(week);
        let week_name = weekBuffer.getName();

        let leaderboardTable;
        if(userId)
        {
            leaderboardTable = await this.getTableRows(constants.waxAccountName, week_name, 'leaderboard', userId, userId, 1, 'i64', 1, false);
        } else {
            leaderboardTable = await this.getTableRows(constants.waxAccountName, week_name, 'leaderboard', null, null, 2, 'i64', 10, true);
        }
        if (leaderboardTable.rows.length === 0) {
            if(userId) {
                return null;
            }
            return [];
        } else {
            if(userId) {
                return leaderboardTable.rows[0];
            }
            return leaderboardTable.rows;
        }
    }

    async request(uri) {
        let flag = false;
        let promises = [];
        for (let i = 0; i < atomicAssetsApis.length; i++) {
            let promise = new Promise(async (resolve, reject) => {
                try {
                    let result = await fetch(atomicAssetsApis[i] + uri, {
                        method: 'get'
                    });
                    result = await result.json();
                    if (!flag && result.success) {
                        flag = true;
                        resolve(result);
                    } else {
                    
                    }
                } catch (e) {
                    console.log(e);
                }
            });
            promises.push(promise);
        }

        let response = await Promise.race(promises);
        
        return response;
    }

    async getTableRows(code, scope, table, lower_bound, upper_bound, index_position, key_type, limit = -1, reverse = false) {
        const rows = await this.rpc.get_table_rows({
            json: true,
            code,
            scope,
            table,
            limit,
            reverse,
            show_payer: false,
            lower_bound,
            upper_bound,
            index_position,
            key_type
        });
        return rows;
    }

}