import constants from "@/constants";

const axios = require('axios');

/**
 * Sends Discord connection creditials, will be used to get the user's Discord info and save in database
 * @param {
 *           accessToken: String,
 *           tokenType: String,
 *           wallet: String
 *         } data Discord connection credentials
 * @return {Promise<String>} result
 */
export async function discordInfo(data) {
    const response = await axios.post(`https://dashboard.pinupwarlords.com/api/discordinfo`, {info:data});
    return response.data;
}

/**
 * Takes a list of asset mapped by their IDs and Gives back a list of the Asset ids on cool down and the timestamp when
 * their CD is over
 * @param {Object} data Soldier Assets
 * @return {Promise<[{assetID:String, timestamp:Number}]>} Array of the assets in Cooldown and their timestamp when it's
 * over
 */
export async function cdCards(wallet) {
    const response = await axios.post(`https://squadleaders.pinupwarlords.com/api/cdcards`, {wallet:wallet});
    return response.data;
}

/**
 * Takes the player's wallet and checks if he has an ongoing game
 * @param {String} wallet wallet address
 * @return {Promise<Boolean | Object>} False if there's no game ongoing, otherwise the game's state
 */
export async function getGame(wallet) {
    const response = await axios.get(`https://squadleaders.pinupwarlords.com/api/getgame?id=${wallet}`);
    return response.data;
}

/**
 * Takes the player's wallet and checks if he has an ongoing game
 * @param {Object} data player queue data
 * @return {Promise<Object>}
 */
export async function queue(data) {
    data.environment = constants.environment;
    const response = await axios.post(`https://squadleaders.pinupwarlords.com/api/queue`, data);
    return response.data;
}

/**
 * Removes the cooldown of the card id and pay ammo
 * @param {String} wallet player wallet address
 * @param {Array<String>} cardid card id
 * @return {Promise<Object>}
 */
export async function removecd(wallet, cardid) {
    const response = await axios.post(`https://squadleaders.pinupwarlords.com/api/removecd`, {wallet: wallet, cardid: cardid});
    return response.data;
}

/**
 * Signals to the backend that the player left the game to remove him from queue
 *
 * @param {String} wallet wallet address
 * @return {Promise<String>} returns "ok" when done
 */
export async function quitSL(wallet) {
    const response = await axios.get(`https://squadleaders.pinupwarlords.com/api/left?id=${wallet}`);
    return response.data;
}

/**
 * Signals to the backend that the player left the game to remove him from queue
 *
 * @param {String} wallet wallet address
 * @return {Promise<String>} returns "ok" when done
 */
export async function endTurn(wallet) {
    const response = await axios.get(`https://squadleaders.pinupwarlords.com/api/endturn?id=${wallet}`);
    return response.data;
}

/**
 * get from backend info about how many players waiting in queue
 *
 * @return {Object} returns queues list with size
 */
export async function queuesInfo() {
    const response = await axios.get(`https://squadleaders.pinupwarlords.com/api/queuesinfo?env=${constants.environment}`);
    return response.data;
}

/**
 * Signals to the backend that the player finished the confiirmation transaction of the game
 *
 * @param {String} wallet wallet address
 * @param {String} trx transaction id
 * @return {Promise<String>} returns "ok" when done
 */
export async function confirmGame(wallet, trx) {
    const response = await axios.post(`https://squadleaders.pinupwarlords.com/api/confirmgame`, {
        player: {
            id:wallet,
            trx:trx
        },
    });
    return response.data;
}

/**
 * Signals to the backend that the player finished the confiirmation transaction of the game
 *
 * @param {String} wallet wallet address
 * @param {Object} data action data
 * @return {Promise<Object>} game's state
 */
export async function playerAction(wallet, data) {
    const response = await axios.post(`https://squadleaders.pinupwarlords.com/api/playeraction`, {
        player: {
            id:wallet,
            actionData:data
        },
    });
    return response.data;
}