<template>
  <section class="subscribe container">
    <article class="subscribe__inner">
      <discord-logo class="discord-logo" />
    </article>
    <img
      id="bullet-1"
      src="@/images/subscribe/1.png"
      alt="Bullet hole"
      data-aos="fade-in"
      data-aos-easing="ease"
    />
    <img
      id="bullet-2"
      src="@/images/subscribe/2.png"
      alt="Bullet hole"
      data-aos="fade-in"
      data-aos-easing="ease"
    />
    <img
      id="bullet-3"
      src="@/images/subscribe/3.png"
      alt="Bullet hole"
      data-aos="fade-in"
      data-aos-easing="ease"
    />
  </section>
</template>

<script>
import DiscordLogo from "./DiscordLogo.vue";

export default {
  name: "DiscordSection",
  components: { DiscordLogo },
  data() {
    return {
      showComingSoon: false,
    };
  },
  methods: {
    handleMouseOver() {
      console.log("test");
    },
  },
};
</script>

<style lang="scss" scoped>
.subscribe {
  background: $dark;
  width: 100%;
  position: relative;
  z-index: 20;
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  padding-bottom: 4rem;

  .subscribe__header {
    font-family: 'Montserrat', sans-serif;
    font-size: 4vw;
    margin-left: 23%;
    margin-bottom: -3px;

    @include media-min(820px) {
      font-size: 2vw;
    }
  }

  .coming-soon {
    transition: opacity ease-in-out 0.2s;
    opacity: 0;
  }

  &__header {
    color: $orange;
    text-align: right;
    margin-left: 3rem;
  }

  #bullet-1,
  #bullet-2,
  #bullet-3 {
    position: absolute;
  }
  #bullet-1 {
    top: -160px;
    left: 100px;
  }
  #bullet-2 {
    bottom: 0px;
    left: 400px;
  }
  #bullet-3 {
    bottom: 30px;
    right: 100px;
  }
  &__inner {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
      text-align: center;
    }
    width: 100%;

    margin: 0 auto;
    margin-top: 5rem;
    @include media-min(820px) {

      width: 60%;
    }

    .discord-logo {
      width: 70%;
    }

    .discord-logo:hover + .coming-soon {
      opacity: 1;
    }
  }
  &__form {
    width: 100%;
    gap: 1rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    @include media-min(820px) {
      flex-direction: row;
      gap: 0;
      width: 80%;
    }
    &__input {
      width: 100%;
      margin-right: 1rem;
      @include media-min(820px) {
        width: 60%;
      }
    }
  }
}
</style>