<template>
  <footer class="footer container">
    <article class="logo">
      <img src="@/images/logo.png" alt="logo" />
    </article>
    <nav class="footer__nav">
      <ul>
        <li v-for="link in links" :key="link.title">
          <a :href="`#${link.slug}`">{{ link.title }}</a>
        </li>
      </ul>
    </nav>
    <div class="footer__social">
      <a class="l-item-width" href="https://www.twitter.com/pinupwarlords" target="_blank">
        <img src="@/images/footer/twitter.png" alt="twitter social icon" />
      </a>
      <a class="l-item-width" href="https://t.me/pinupwarlords" target="_blank">
        <img src="@/images/footer/telegram.png" alt="telegram social icon" />
      </a>
      <!-- <div class="tooltip-container"> -->
      <a class="l-item-width" href="https://pinupwarlords.medium.com" target="_blank">
        <img src="@/images/footer/medium.png" alt="medium social icon" />
      </a>
      <!-- <div class="tooltip">Soon</div>
      </div> -->
      <a class="l-item-width" href="https://discord.gg/pinupwarlords" target="_blank">
        <img src="@/images/footer/discord.png" alt="discord social icon" />
      </a>
    </div>
    <div class="footer__labels">
      <span>© 2023 PinupWarlords. All rights reserved</span>
      <div class="footer__labels__terms">
        <span>
          <router-link to="/terms">Terms</router-link></span
        >
        |
        <span
          ><router-link to="/privacy-policy">Privacy policy</router-link></span
        >
        |
        <a
            href="javascript:void(0);"
            @mouseover="hoveringImprint = true"
            @mouseleave="hoveringImprint = false"
        >Imprint</a
        >
        <p class="tooltip" :class="{ visible: hoveringTerms }">
          Upon release of the game.
        </p>
        <p class="imprint" :class="{ visible: hoveringImprint }">
          Dragon Dream Digital Ltd<br/><br/>
          Legal Address: Escazu San Rafael,<br/>
          Edificio Ebc Centro Corporativo, 8, San Jose, Costa Rica<br/><br/>
          Registration Number: 586911-2022<br/>
          <br/>
          Head of Publication: Hadjadj Taha<br/>
          <br/><br/>
          Contact: contact@pinupwarlords.com<br/>
        </p>
      </div>
      <a  href="https://dragondreamstudio.com/" target="_blank">A game by Dragon Dream Studio</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      links: [
        { title: "The Cards", slug: "the-cards" },
        { title: "The Game", slug: "the-game" },
        { title: "Play to Earn", slug: "play-to-earn" },
        { title: "Buy Cards", slug: "buy-cards" },
      ],
      hoveringTerms: false,
      hoveringImprint: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.l-item-width {
  max-width: 25%;
}
.footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url("../images/footer/bg.png") no-repeat center center/cover;

  @include media-min(500px) {
    height: auto;
  }
  width: 100%;
  .logo {
    width: 100px;
    margin: 0 auto;
    img {
      height: auto;
      width: 100%;
    }
  }

  &__nav {
    padding: 2rem 0;
    margin: 2rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.096);
    border-bottom: 1px solid rgba(255, 255, 255, 0.096);

    @include media-min(500px) {
      width: 70%;
      margin: 0 auto;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      font-weight: bold;
      align-items: center;
      justify-content: space-around;
      li {
        margin: 0 1rem;
        transition: all $transition-fast;
        cursor: pointer;
        a {
          color: white;
          &:hover {
            color: $orange;
            @include orange-glow-text;
          }
        }
      }
    }
  }

  &__social {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 1rem;
    flex-wrap: wrap;

    .tooltip-container {
      position: relative;
      .tooltip {
        position: absolute;
        top: -20px;
        width: 150px;
        left: 30px;
        right: 0;
        white-space: nowrap;
        opacity: 0;
        transition: all $transition-fast;
        transform: translateY(-10px);
      }
    }
    .tooltip-container:hover {
      .tooltip {
        opacity: 1;
        transform: translateY(0px);
      }
    }

    @include media-min(500px) {
      margin-top: 4rem;

      width: 40%;
    }
    a {
      transition: all 0.2s ease-in-out;
      transform: translateY(0rem);
    }
    a:hover {
      transform: translateY(-1rem);
    }
    img {
      height: 100%;
      width: auto;
    }
  }

  &__labels {
    margin-top: 4rem;
    width: 100%;
    color: $gray;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__terms {
      position: relative;
      .tooltip {
        position: absolute;
        top: -40px;
        width: 150px;
        left: 0;
        right: 0;
        white-space: nowrap;
        opacity: 0;
        transition: all $transition-fast;
        transform: translateY(-10px);
        &.visible {
          opacity: 1;
          transform: translateY(0px);
        }
      }
      .imprint {
        position: absolute;
        top: -270px;
        left: -40;
        width: 150%;
        opacity: 0;
        transition: all $transition-fast;
        transform: translateY(-10px);
        background: rgba(0, 0, 0, 0.9);
        padding: 20px;
        height: 0;
        border-radius: 25px;
        &.visible {
          opacity: 1;
          height: auto;
          transform: translateY(0px);
        }
        @include media-min(610px) {
          width: 480px;
          left: -300px;
          top: -250px;
        }
        @include media-min(1030px) {
          left: -60px;
          width: 500px;
        }
      }
    }
    a {
      text-decoration: none;
      text-shadow: 0 0 transparent;
      transition: all $transition-fast;
      color: $gray;

      &:hover {
        color: $orange;
        @include orange-glow-text;
      }
    }
    #terms-link:hover + #terms-tooltip {
      color: red;
    }
  }
}
</style>