<template>
  <article class="hive-item">
      <p class="hive-item__text">
          <slot>

          </slot>
      </p>
  </article>
</template>

<script>
export default {
  name: "HiveItem",
};
</script>

<style lang="scss" scoped>
.hive-item {
    display: flex;
    justify-content: center;
    align-items: center;
  position: absolute;
  right: 22.9vw;
    height: 10.6vw;
    width: 12.5vw;
    bottom: 3vw;
  background: #101117;
  z-index:2;
  -webkit-clip-path: polygon(
    25% 0%,
    75% 0%,
    100% 50%,
    75% 100%,
    25% 100%,
    0% 50%
  );
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    &__text{
        text-align: center;
        width: 70%;
        font-size: 1vw;
    }
}
</style>