import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vClickOutside from "v-click-outside";
import VModal from "vue-js-modal";
import axios from "axios";
import VideoBg from "vue-videobg";
import Vuex from "vuex";
import Vuelidate from "vuelidate";

import { VTooltip, VPopover, VClosePopover } from "v-tooltip";
import "v-tooltip/dist/v-tooltip.css";
Vue.use(Vuelidate);
Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.component("v-popover", VPopover);
Vue.use(Vuex);
import AOS from "aos";
import "aos/dist/aos.css";
app.AOS = new AOS.init({ disable: "phone" });

import Wallet from "./classes/wallet.js";

const defaultChain = "WAX";
let wallet = new Wallet(defaultChain);

const store = new Vuex.Store({
  state: {
    currentVideo: 0,
    shouldShowAudioSettings: true,
    musicEnabled: false,
    isAlreadyPlaying: false,
    wallet,
  },
  actions: {
    changeVideos({ commit }, allVideos) {
      if (allVideos) {
        commit("changeCurrentVideo", allVideos);
      }
    },
    changeShouldShowAudioSettings({ commit }, payload) {
      commit("changeShouldShowAudioSettings", payload);
    },
    changeCurrentVideo({ commit }, payload) {
      commit("changeCurrentVideoOnDestroy");
    },
    async login({ dispatch }, walletName) {
      await wallet.login(walletName);
    },
    async logout() {
      await wallet.logout();
    },
  },
  getters: {
    getCurrentVideo: (state) => {
      return state.currentVideo;
    },
    shouldShowAudioSettings: (state) => state.shouldShowAudioSettings,
    isMusicEnabled: (state) => {
      return state.musicEnabled;
    },
    isMusicAlreadyPlaying: (state) => {
      return state.isAlreadyPlaying;
    }
  },
  mutations: {
    changeCurrentVideo(state, payload) {
      if (state.currentVideo === 5) {
        payload[state.currentVideo].classList.remove("active");
        state.currentVideo = 0;
        payload[state.currentVideo].classList.add("active");
        return;
      }
      payload[state.currentVideo].classList.remove("active");
      state.currentVideo++;
      payload[state.currentVideo].classList.add("active");
    },
    changeShouldShowAudioSettings(state, payload) {
      state.shouldShowAudioSettings = payload;
    },
    changeCurrentVideoOnDestroy(state) {
      state.currentVideo = 0;
    },
    setMusicPermission(state, payload) {
      state.musicEnabled = payload;
    },
    setMusicAlreadyPlaying(state, payload) {
      state.isAlreadyPlaying = payload;
    },
  },
});

Vue.component("video-bg", VideoBg);

Vue.use({
  install(Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: "https://admin.pinupwarlords.com",
    });
  },
});

Vue.use(VModal);
Vue.use(vClickOutside);

const icons = require.context("./assets/svg-icons", false, /.svg/);
icons.keys().forEach((element) => {
  let test = element.replace(/^\.\//, "").replace(/\.\w+$/, "") + "-icon";
  Vue.component(test, icons(element));
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
