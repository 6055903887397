<template>
  <div class="the-radar-pulse"></div>
</template>

<script>
export default {
  name: "TheRadarPulse",
  data() {
    return {
      interval: null
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      if(document.getElementById("the-radar")) {

        const randomX = Math.floor(Math.random() * 100);
        const randomY = Math.floor(Math.random() * 100);

        const parentPos = document
          .getElementById("the-radar")
          .getBoundingClientRect();
        const childPos = document
          .getElementById("the-radar-line")
          .getBoundingClientRect();
        const relativePos = {};

        relativePos.top = childPos.top - parentPos.top;
        relativePos.right = childPos.right - parentPos.right;
        relativePos.bottom = childPos.bottom - parentPos.bottom;
        relativePos.left = childPos.left - parentPos.left;

        //console.log(relativePos);

        if (this.$el) {
          this.$el.style.right = -relativePos.right + randomX + "px";
          this.$el.style.bottom = -relativePos.bottom + randomY + "px";
          this.$el.classList.toggle("show");
        }
      }
    }, 400);
  },
  unmounted() {
    clearInterval(this.interval);
  }
};
</script>

<style lang="scss" scoped>
.the-radar-pulse {
  position: absolute;
  //   bottom: 20px;
  //   right: 100px;
  background: rgb(62, 250, 96);
  background: radial-gradient(
    circle,
    rgba(62, 250, 96, 0.947514) -1%,
    rgba(0, 0, 0, 0) 49%
  );
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  &.show {
    opacity: 1;
  }
}
</style>