<template>
  <section id="the-game" class="game-wrap container container-vertical">
    <img
      src="../images/thegame/pic_smoke.png"
      alt="Smoke effect"
      class="smoke-effect"
    />
    <img
      id="camouflage-left"
      src="../images/thegame/camouflage_left.png"
      alt="Camouflage pattern on the left"
    />
    <article class="game-wrap__text">
      <h1 class="title title-gradient">The Game</h1>
      <p class="bold-paragraph">
        It's a pure PVP card based game where players compete using their card
        sets
      </p>
      <p class="paragraph">
        Main cards consist of NFTs and you'll be able to "power up" using FTs to
        enhance your character's abilities. Smart use of various combinations
        will increase your chances of winning
      </p>
      <div class="game-wrap__screen">
        <img src="../images/thegame/screen.png" alt="Military Screen" />
        <div class="game-wrap__video">
          <div id="video-slider" class="video-slider">
            <video
              playsinline
              autoplay
              loop
              src="../assets/videos/screen/1.mp4"
            ></video>
            <video
              playsinline
              autoplay
              loop
              src="../assets/videos/screen/2.mp4"
            ></video>
            <video
              playsinline
              autoplay
              loop
              src="../assets/videos/screen/3.mp4"
            ></video>
            <video
              playsinline
              autoplay
              loop
              src="../assets/videos/screen/4.mp4"
            ></video>
            <video
              playsinline
              autoplay
              loop
              src="../assets/videos/screen/5.mp4"
            ></video>
            <video
              playsinline
              autoplay
              loop
              src="../assets/videos/screen/6.mp4"
            ></video>
            <video
              playsinline
              autoplay
              loop
              src="../assets/videos/screen/7.mp4"
            ></video>
            <video
              playsinline
              autoplay
              loop
              src="../assets/videos/screen/8.mp4"
            ></video>
          </div>
        </div>
      </div>
    </article>
    <article id="camouflage-right">
      <div>
        <img
          src="@/images/thegame/right.png"
          alt="Hive shape with images of weapons"
        />
      </div>
      <hive-item>
        Some heavy weapons will require specific NFTs to be used in a PVP game.
      </hive-item>
    </article>
  </section>
</template>

<script>
import HiveItem from "./HiveItem.vue";
export default {
  name: "TheGamesSection",
  components: {
    HiveItem,
  },
  data() {
    return {
      sectionIsVisible: false,
      interval: null,
    };
  },

  computed: {
    musicEnabled() {
      return this.$store.getters["isMusicEnabled"];
    },
  },
  watch: {
    sectionIsVisible(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.videoSliderInit();
      }
    },
  },
  mounted() {
    this.muteAllVideos();
    document.addEventListener("scroll", () => {
      let sectionIsVisible = this.isInViewport();
      this.sectionIsVisible = sectionIsVisible;
    });
  },

  methods: {
    muteAllVideos() {
      let videos = Array.from(document.getElementById("video-slider").children);
      if (videos) {
        videos.forEach((video) => {
          video.volume = 0;
        });
      }
    },
    muteVideo(video) {
      if (!video) return;
      video.volume = 0;
    },
    unmuteVideo(video) {
      if (!video) return;

      video.volume = 1;
    },

    videoSliderInit() {
      let weaponSlider = document.getElementById("video-slider");
      let videos = weaponSlider.children;
      this.muteAllVideos();
      clearInterval(this.interval);
      if (!this.sectionIsVisible) {
        this.muteAllVideos();
        clearInterval(this.interval);
        weaponSlider.style.left = `-0px`;
        return;
      }
      let noOfVideos = videos.length;
      let counter = 1;
      const travel = 416;
      let no = 416;
      if (this.musicEnabled) {
        this.unmuteVideo(videos[0]);
      }

      this.interval = setInterval(() => {
        if (this.musicEnabled) {
          this.unmuteVideo(videos[counter]);
          this.muteVideo(videos[counter - 1]);
        }

        if (counter >= noOfVideos) {
          if (this.musicEnabled) {
            this.unmuteVideo(videos[0]);
          }

          counter = 0;
          no = 0;
        }
        weaponSlider.style.left = `-${no}px`;
        no += travel;
        counter++;
      }, 3000);
    },
    isInViewport() {
      let el = document.getElementById("video-slider");
      if (!el) return;
      let bounding = el.getBoundingClientRect();
      return (
        bounding.top >= 0 &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.game-wrap {
  background: $dark;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  #camouflage-left {
    position: absolute;
    left: 0;
  }
  #camouflage-right {
    position: absolute;
    right: 0;
    width: 50%;
    img {
      height: auto;
      width: 100%;
    }
  }
  .smoke-effect {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__text {
    overflow-x: visible;
    z-index: 10;
    width: 100%;
    @include media-min(820px) {
      width: 46%;
    }
    > p:first-of-type {
      margin-bottom: 1.5rem;
    }
  }

  &__screen {
    position: relative;
    width: 500px;
    margin-top: 1rem;
    @include media-max(820px) {
      transform-origin: left;
      transform: scale(0.5);
    }

    video {
      object-fit: cover;
      height: 100%;
      //   width: 100%;
      min-width: 416px;
    }
    img {
      height: auto;
      width: 100%;
    }
  }
  &__video {
    background: black;
    transform: rotate(0.1deg);
    overflow: hidden;
    position: absolute;
    top: 29px;
    right: 44px;
    bottom: 71px;
    left: 40px;
  }
  .video-slider {
    transition: ease-in-out 0.2s all;
    display: flex;
    flex-basis: 300px;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}
</style>