<template>
    <button @click="$emit('click')" class="button-primary">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name:'AppButton'
    }
</script>

<style lang="scss" scoped>
.button-primary{
    padding: 15px 35px;
    background: $orange;
    border-radius: 10px;
    transition: all $transition-fast;
      box-shadow: 0px 0px 0px transparent;
    font-weight: bold;
        &:hover {
          @include orange-glow;
        }
}
</style>