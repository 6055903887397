<template>
  <section id="play-to-earn" class="play-wrap container">
    <h1 class="title title-gradient">
      PLAY TO EARN
    </h1>
    <div class="play-wrap__inner">
      <div class="play-wrap__cards">
        <article
          v-for="card in cards"
          :key="card.img"
          class="play-wrap__cards__card"
        >
          <div class="card" :class="{ 'inset-left': card.insetLeft }">
            <img :src="`../images/playtoearn/${card.img}`" alt="Card icon" />
            <p class="paragraph">
              {{ card.text }}
            </p>
          </div>
          <div class="right" data-aos="slide-right" data-aos-easing="ease">
            <img
              src="../images/playtoearn/bullet-trail.png"
              alt="Bullet smoke trail effect"
            />
            <img
              src="../images/playtoearn/bullet.png"
              alt="Bullet smoke trail effect"
            />
          </div>
        </article>
      </div>
      <div class="play-wrap__image">
        <img
          src="../images/playtoearn/target.png"
          alt="Target with dollar inside"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PlaySection",
  data() {
    return {
      cards: [
        {
          img: "icon-1.png",
          text:
            "Win your opponents NFTs and resell them on the secondary market",
        },
        {
          img: "icon-2.png",
          text:
            "Win battles and earn a utility token that receives commissions on sales",
          insetLeft: true,
        },
        {
          img: "icon-3.png",
          text: "Play for the leaderboard to earn weekly prizes and win tournaments!",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.play-wrap {
  // background-image: url("../images/playtoearn/background.png");
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: url("../images/playtoearn/bg.png") no-repeat bottom center/cover;
  &__inner {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @include media-min(820px) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }
  &__cards {
    @include media-min(820px) {
      grid-column: span 3 / span 3;
    }

    &__card {
      width: 100%;
      display: flex;
      align-items: center;
      & + & {
        margin-top: 1rem;
      }
      .card {
        z-index: 1;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 135px;
        width: 100%;

        background: $dark;
        box-shadow: inset 0px 0px 15px rgba(255, 255, 255, 0.3);
        border-radius: 20px;
        img {
          height: 35px;
          width: 20%;
          object-fit: contain;
          margin-right: 0.5rem;
        }
        p {
          width: 60%;
          margin: 0 auto;
        }
        @include media-min(820px) {
          width: 50%;
          &.inset-left {
            margin-left: 5rem;
          }
          p {
            font-size: 1vw;
          }
        }
      }

      .right {
        margin-left: -10rem;
        height: 30px;
        width: 50%;
        display: flex;
        img {
          height: 100%;
          width: auto;
          &:last-child {
            margin-left: -15rem;
          }
        }
      }
    }
  }
  &__image {
    img {
      height: auto;
      width: 100%;
      display: none;
    }
    @include media-min(820px) {
      grid-column: span 2 / span 2;
      img {
        display: block;
      }
    }
  }
}
</style>