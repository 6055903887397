<template>
  <div class="l-home">
    <disclaimer-popup />
    <div class="video-wrap" v-if="!isMobile">
      <div id="homepage-videos" class="video-wrap__inner">

        <video
            playsinline
            class="c-video active"
            autoplay
            muted
            loop
            :src="video5"
        ></video>


        <video
            playsinline
            class="c-video"
            autoplay
            muted
            loop
            :src="video6"
        ></video>

        <video
          playsinline
          class="c-video"
          autoplay
          muted
          loop
          :src="video4"
        ></video>
        
        <video
          playsinline
          class="c-video"
          autoplay
          muted
          loop
          :src="video1"
        ></video>

        <video
          playsinline
          class="c-video"
          autoplay
          muted
          loop
          :src="video2"
        ></video>

        <video
          playsinline
          class="c-video"
          autoplay
          muted
          loop
          :src="video3"
        ></video>

      </div>

      <div v-if="!shouldShowAudioSettings" class="l-right-img-wrap">
        <img
          :class="['l-right-img-wrap__img', { active: getCurrentVideo === 4 }]"
          src="../../images/yellow.png"
        />
        <img
          :class="['l-right-img-wrap__img', { active: getCurrentVideo !== 4 }]"
          src="../../images/green.png"
        />
        <div class="play-now-btn-wrap">
          <button class="l-right-img-wrap__btn" @click="openPlayNowModal"></button>
        </div>
        <div class="helmet-btn-wrap">
          <!--<button :disabled="isPlayDisabled" @click="openPlayNowModalPixi" class="l-right-img-wrap-helmet__btn"></button>-->
        </div>
      </div>
      <scroll-down @click="scrollDown" />
    </div>

    <div v-if="isMobile">
      <div class="cover-image-mobile-warp">
        <img class="cover-image-mobile" src="../../images/home-mobile.png" />

        <div class="helmet-btn-wrap-mobile">
          <button class="cover-image-mobile-warp__btn" @click="openPlayNowModal"></button>
        </div>
      </div>
      <scroll-down @click="scrollDown" />
    </div>

    <transition name="fade">
      <div v-if="shouldShowAudioSettings" class="l-sound">
        <div class="l-sound__ctn">
          <div class="l-sound__title">
            <img class="u-width-100" src="../../images/sound-text.png" alt="" />
          </div>
          <div class="l-sound__buttons">
            <button class="c-btn c-btn--sound" @click="setSound(true)">
              <img class="u-width-100" src="../../images/sound-on.png" alt="" />
            </button>
            <button class="c-btn c-btn--sound" @click="setSound(false)">
              <img
                class="u-width-100"
                src="../../images/sound-off.png"
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
    </transition>
    <the-cards-section />
    <the-game-section />
    <play-section />

    <!-- <subscribe-section /> -->
    <road-map-section />
    <gamefi-section />
    <buy-cards-section />

    <!-- PLAY NOW MODAL -->
    <app-modal :is-open="playNowModalOpen" @close-modal="closePlayNowModal">
      <div class="play-now-modal">
        <h1 class="font-crimson title-gradient modal-header">
          To continue please select an option
        </h1>
        <div v-if="!providerIsLoading" class="play-now-modal__actions">
          <div
            v-for="provider in providers"
            :key="provider.slug"
            class="provider"
            @click="providerClicked(provider.slug)"
          >
            <div class="provider__img">
              <img
                :src="`../../images/playnowmodal/${provider.img}`"
                :alt="`${provider.text} wallet logo`"
              />
            </div>
            <div class="provider__cta">
              <p>{{ provider.text }}</p>
              <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.375 8.12503H18.8308L12.1467 1.75853C11.7967 1.42516 11.7831 0.87128 12.1165 0.52128C12.4494 0.171718 13.0033 0.157718 13.3537 0.491093L20.9873 7.76234C21.3176 8.09309 21.5 8.53234 21.5 9.00003C21.5 9.46728 21.3176 9.90697 20.9719 10.2522L13.3533 17.5085C13.184 17.67 12.967 17.75 12.75 17.75C12.519 17.75 12.288 17.659 12.1161 17.4783C11.7827 17.1283 11.7963 16.5749 12.1463 16.2415L18.8584 9.87503H1.375C0.892 9.87503 0.5 9.48303 0.5 9.00003C0.5 8.51703 0.892 8.12503 1.375 8.12503Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
        <h1 v-else class="font-crimson title-gradient">loading...</h1>
        <p class="wallet-links">
          <span>*</span> Create a Wax Cloud Wallet
          <a class="wallet-link" href="https://all-access.wax.io/">here</a>,
          Create an Anchor Wallet
          <a class="wallet-link" href="https://greymass.com/en/anchor/">here</a>
        </p>
      </div>
    </app-modal>
    <discord-section />
    <AppFooter />
  </div>
</template>

<script>
import DisclaimerPopup from "@/components/DisclaimerPopup";
import audio from "../../assets/sounds/audio.mp3";
import video1 from "../../assets/videos/1.mp4";
import video2 from "../../assets/videos/2.mp4";
import video3 from "../../assets/videos/3.mp4";
import video4 from "../../assets/videos/4.mp4";
import video5 from "../../assets/videos/5.mp4";
import video6 from "../../assets/videos/6.mp4";

import { mapActions, mapGetters } from "vuex";
import TheCardsSection from "../../components/TheCardsSection.vue";
import TheGameSection from "../../components/TheGameSection.vue";
import ScrollDown from "../../components/ScrollDown.vue";
import RoadMapSection from "../../components/RoadMapSection.vue";
import DiscordSection from "../../components/DiscordSection.vue";
import BuyCardsSection from "../../components/BuyCardsSection.vue";
import GamefiSection from "../../components/GamefiSection.vue";

import PlaySection from "../../components/PlaySection";
import AppModal from "../../components/modals/AppModal";
import AppFooter from "../../components/AppFooter.vue";

import {discordInfo} from "../../classes/apiService"

export default {
  name: "HomePage",
  components: {
    DisclaimerPopup,
    TheCardsSection,
    TheGameSection,
    ScrollDown,
    BuyCardsSection,
    PlaySection,
    GamefiSection,
    AppModal,
    AppFooter,
    RoadMapSection,
    DiscordSection,
  },
  data() {
    return {
      enabledSound: false,
      audio: audio,
      music: null,
      isMobile: false,
      video1: video1,
      video2: video2,
      video3: video3,
      video4: video4,
      video5: video5,
      video6: video6,
      currentVideo: 0,
      videoIterval: "",
      scrolledToBottom: false,
      playNowModalOpen: false,
      email: "",
      providers: [
        {
          img: "wax.png",
          slug: "wax",
          text: "WAX Cloud Wallet",
        },
        {
          img: "anchor.png",
          slug: "anchor",
          text: "Anchor",
        },
        {
          img: "wombat.png",
          slug: "wombat",
          text: "Wombat",
        },
      ],
      providerIsLoading: false,
    };
  },
  computed: {
    ...mapGetters(["shouldShowAudioSettings", "getCurrentVideo"]),
    getVideos() {
      return [...document.querySelectorAll("video")];
    },
    isMusicAlreadyPlaying() {
      return this.$store.getters["isMusicAlreadyPlaying"];
    },
    /*isPlayDisabled() {
      let url = window.location.hostname;
      if(url.includes("test.") || url.includes("localhost")) {
        return false;
      }
      return true;
    }*/
  },
  async created(){
    let url = window.location.hostname;
    console.log(url)
    if (url.includes("access_token")){
      const fragment = new URLSearchParams(url.split("#")[1]);
      const [accessToken, tokenType, wallet] = [fragment.get('access_token'), fragment.get('token_type'), fragment.get('state')];
      if (accessToken){
        const payload = {
          accessToken: accessToken,
          tokenType: tokenType,
          wallet: wallet
        }
        let res = await discordInfo(payload)
        console.log(res)
        window.close();
      }
    }
  },
  mounted() {
    document.getElementsByTagName("html").item(0).removeAttribute("style")
    // prevent scroll of the page if the modal is open
    document.body.classList.add("modal-open");
    this.music = new Audio(this.audio);
    this.music.loop = true;
    this.videoIterval = setInterval(
      () => this.changeVideos(this.getVideos),
      9000
    );
    this.scroll();
    if (this.$store.state.musicEnabled) {
      this.setSound(true);
    }

    this.isMobile = window.innerWidth <= 820

    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 820
    });
  },

  beforeDestroy() {
    clearInterval(this.videoIterval);
    this.changeCurrentVideo();
  },

  methods: {
    // PROVIDER LOGIC ANCHOR AND WAX
    async proceedWithWax() {
      // CONNECT TO WAX HERE
      this.providerIsLoading = true;
      console.log("CLICKED ON WAX");
      //timeout is for loading purposes only, remove it when you have connected to backend

      //setTimeout(() => {
      this.providerIsLoading = false;
      this.closePlayNowModal();
      //}, 2000);

      await this.$store.dispatch("login", "wax");
      if (this.$store.state.wallet.getUsername()) {
        if (this.enabledSound) {
          this.toggleAudio();
        }
        this.$router.push({ path: "/play", query: this.$route.query });
      }
    },
    async proceedWithAnchor() {
      // CONNECT TO ANCHOR HERE
      this.providerIsLoading = true;
      console.log("CLICKED ON ANCHOR");
      //timeout is for loading purposes only, remove it when you have connected to backend

      this.$store.dispatch("login", "anchor");

      //setTimeout(() => {
      this.providerIsLoading = false;
      this.closePlayNowModal();
      //}, 2000);

      await this.$store.dispatch("login", "anchor");
      if (this.$store.state.wallet.getUsername()) {
        if (this.enabledSound) {
          this.toggleAudio();
        }

        let query = this.$route.query;
        this.$router.push({ path: "/play", query });
      }
    },
    async proceedWithWombat() {

      console.log("CLICKED ON Wombat");
      let url = window.location.hostname;
      this.providerIsLoading = true;

      this.$store.dispatch("login", "wombat");

      this.providerIsLoading = false;
      this.closePlayNowModal();
      //}, 2000);

      await this.$store.dispatch("login", "wombat");
      if (this.$store.state.wallet.getUsername()) {
        if (this.enabledSound) {
          this.toggleAudio();
        }

        let query = this.$route.query;
        this.$router.push({ path: "/play", query });
      }
    },
    providerClicked(slug) {
      if (slug === "wax") {
        this.proceedWithWax();
      }
      if (slug === "anchor") {
        this.proceedWithAnchor();
      }
      if (slug === "wombat") {
        this.proceedWithWombat();
      }
    },

    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight ===
          document.documentElement.offsetHeight;

        if (bottomOfWindow) {
          this.scrolledToBottom = true; // replace it with your code
        } else {
          this.scrolledToBottom = false;
        }
      };
    },
    scrollDown() {
      const scrollTo = document.getElementById("the-cards");
      scrollTo.scrollIntoView();
    },
    setSound(enabled) {
      this.changeShouldShowAudioSettings(false);
      document.body.classList.remove("modal-open");
      this.$store.commit("setMusicPermission", enabled);
      if (!enabled) {
        this.enabledSound = false;
        this.$store.commit("setMusicAlreadyPlaying", false);
      }

      if (enabled && !this.isMusicAlreadyPlaying) {
        this.$store.commit("setMusicAlreadyPlaying", true);
        this.music.play();
        this.enabledSound = true;

        this.music.addEventListener(
          "ended",
          function () {
            this.currentTime = 0;
            this.play();
          },
          false
        );
      }

      if (this.$route.query.hasOwnProperty("confirmed")) {
        this.$modal.show("confirmModal");
      }
    },
    // MODALS OPENNING AND CLOSING
    openPlayNowModal() {
      this.playNowModalOpen = true;
      this.gtag_report_conversion();
    },
    closePlayNowModal() {
      this.playNowModalOpen = false;
    },

    toggleAudio() {
      this.enabledSound = !this.enabledSound;
      this.enabledSound ? this.music.play() : this.music.pause();
    },
    ...mapActions([
      "changeVideos",
      "changeShouldShowAudioSettings",
      "changeCurrentVideo",
    ]),
    gtag_report_conversion(url) {
      var callback = function () {
        if (typeof(url) != 'undefined') {
          window.location = url;
        }
      };
      function gtag(){dataLayer.push(arguments);}
      gtag('event', 'conversion', {
        'send_to': 'AW-11033217903/gkVRCOLr24MYEO-Who0p',
        'event_callback': callback
      });
      return false;
    },
  },
};
</script>

<style lang="scss">
.modal-header {
  text-transform: uppercase;
  margin: 0 auto;
  font-size: 50px;
  line-height: 55px;
  font-weight: 400;

  @include media-max(750px){
    line-height: 40px;
  }
  @include media-max(600px){
    font-size: 35px;
    line-height: 35px;
  }
  @include media-max(430px){
    font-size: 30px;
    line-height: 30px;
  }
  @include media-max(350px){
    font-size: 30px;
    line-height: 30px;
  }
}
.play-now-modal {
  height: 100%;
  width: 100%;
  padding: 4rem;
  text-align: center;
  @include media-max(705px){
    padding: 4rem 1rem;
  }
}

.cover-image-mobile-warp{
  position: relative;
  margin-left: auto;
  top: 0;
  right: 0;

}
.helmet-btn-wrap-mobile{
  position: absolute;
  width: 60%;
  height: 6.5%;
  left: 20%;
  top: 56.2%;
  mix-blend-mode: hard-light;

  &:hover{
    background: rgba(199, 173, 5, 0.51);
  }
}
.cover-image-mobile-warp__btn{
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.cover-image-mobile {
  width: 100%;
}

.play-now-modal {
  &__actions {
    width: 60%;
    margin: 2rem auto 0;

    @include media-max(370px){
      margin: 1rem auto 0;
    }
  }
  .wallet-links {
    margin-top: 1rem;
    span {
      color: $orange;
    }
    .wallet-link {
      color: $orange;
      border-bottom: 1px solid $orange;
    }
  }
  .provider {
    width: 100%;
    min-width: 180px;
    border: 1px solid white;
    border-radius: 10px;
    height: 60px;
    display: flex;
    cursor: pointer;
    &__img,
    &__cta {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      color: white;
    }
    &__img {
      border-right: 1px solid white;
      height: 100%;
      width: 70px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .provider:hover {
    border-color: $orange;
    .provider__cta {
      color: $orange;
    }
    .provider__img {
      border-color: $orange;
    }
    svg {
      stroke: $orange;
    }
  }
  .provider + .provider {
    margin-top: 1.5rem;
  }
}

.v-popper--theme-tooltip .v-popper__inner {
  width: auto;
  height: auto;
  background-color: #0000004a;
  font-size: 2rem;
  font-weight: bold;
  padding: 1.5rem;
  opacity: 0.7;
  border-radius: 7px;
  margin-bottom: 1rem;

  & > div {
    padding: 5px 0 5px 0;
  }
}

.v-popper--theme-tooltip .v-popper__arrow-container {
  margin-bottom: 1.5rem;
}
</style>