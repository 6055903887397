import Vue from 'vue';
import Router from 'vue-router';
import HomePage from '../pages/home-page/home-page.vue';
import PrivacyPage from '../pages/privacy/privacy-page.vue';
import TermsPage from '../pages/terms/terms-page.vue';
import PlayPage from '../pages/play-page/play-page.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: HomePage,
    },
    {
      path: '/privacy-policy',
      component: PrivacyPage
    },
    {
      path: '/terms',
      component: TermsPage
    },
    {
      path: '/play',
      component: PlayPage
    }
  ],
});

export default router;
